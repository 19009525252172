import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, IMultiChoiceButtonOption, MultiChoice } from '@ruby-labs/ui-core-kit';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';

import { NextButton } from 'components/next-button';
import { FloatedButton } from 'components/floated-button';
import { getMultiChoiceSelected } from 'helpers/get-multichoice-button-select-options';

export const PhysicalLimitations = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const pageKey = LocalStorageKeys[RoutePath.PhysicalLimitations];

    const { pageValue, setPageValue } = useLocalStorage({
        key: pageKey,
        defaultValue: [],
    });

    const { pageValue: userAnswer, setPageValue: setUserAnswer } = useLocalStorage({
        key: `${pageKey}-userAnswer`,
        defaultValue: '',
    });

    const multiChoices: IMultiChoiceButtonOption[] = [
        { value: 'none', label: t('physical-limitations-option-none') },
        { value: 'back', label: t('physical-limitations-option-back') },
        { value: 'knees', label: t('physical-limitations-option-knees') },
        { value: 'limited-mobility', label: t('physical-limitations-option-limited-mobility') },
        { value: 'injury', label: t('physical-limitations-option-injury') },
        { value: 'other', label: t('physical-limitations-option-other'), placeholder: 'Add your answer here', userAnswer, setUserAnswer },
    ];

    const handleClick = () => {
        const { values, labels } = getMultiChoiceSelected(pageValue);

        saveAnswers({
            key: pageKey,
            itemQuestion: { question: t('physical-limitations-title'), value: labels },
        });

        pageConfiguration.handleOnPageComplete({
            value: values,
            userAnswer: values.includes('other') ? userAnswer?.toString()?.trim() : ''
        });
    };

    return (
        <>
            <Heading title={t('physical-limitations-title')} subtitle={t('physical-limitations-subtitle')}/>

            <MultiChoice
                options={multiChoices}
                deselectWhenValues={['none']}
                selectedOptions={pageValue}
                onSelect={setPageValue} />

            <FloatedButton floated={pageValue.length} withGradient>
                <NextButton onClick={handleClick} disabled={!pageValue.length} />
            </FloatedButton>
        </>
    );
};
