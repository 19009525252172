import React, {useContext, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { Heading, ISingleChoiceButtonOption, SingleChoice } from '@ruby-labs/ui-core-kit';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { ExperimentsKeys } from 'constants/experiments';

export const AtIdealShape = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const pageKey = LocalStorageKeys[RoutePath.AtIdealShape];

    const { pageValue, setPageValue }: any = useLocalStorage({
        key: pageKey,
        defaultValue: '',
    });

    const handleClick = (option: ISingleChoiceButtonOption) => {
        saveAnswers({
            key: pageKey,
            itemQuestion: { question: t('at-ideal-shape-title'), value: option.label },
        });
        setPageValue(option);

        pageConfiguration.handleOnPageComplete(option.value);
    };

    const options: ISingleChoiceButtonOption[] = [
        { value: 'one-to-six-month', label: t('at-ideal-shape-option-one-to-six-month') },
        { value: 'six-to-twelve-month', label: t('at-ideal-shape-option-six-to-twelve-month') },
        { value: 'one-to-two-year', label: t('at-ideal-shape-option-one-to-two-year') },
        { value: 'two-plus-year', label: t('at-ideal-shape-option-two-plus-year') },
        { value: 'right-now', label: t('at-ideal-shape-option-right-now') },
        { value: 'never-was', label: t('at-ideal-shape-option-never-was') },
    ];

    const mg_add_graphs_screen = useFeatureValue(ExperimentsKeys.mg_add_graphs_screen, '0');

    useEffect(() => {
        localStorage.setItem(ExperimentsKeys.mg_add_graphs_screen, mg_add_graphs_screen as string);
    }, [mg_add_graphs_screen]);

    return (
        <>
            <Heading title={t('at-ideal-shape-title')} />

            <SingleChoice
                options={options}
                selectedOption={pageValue}
                onSelect={handleClick} />
        </>
    );
};
