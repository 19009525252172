import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    buttonWrapper: {
        '& button': {
            padding: '5px 13px',
            maxHeight: 'unset',
            '& img': {
                width: '50px',
                height: '68px',
            }
        }
    }
});
