import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

import { BaseContext } from 'context/base.context';

import { disableBrowserBackButton } from 'helpers/disable-browser-back-button';
import { handleRedirectToPwa } from 'helpers/redirect-to-pwa';

import { APP_TYPE_APP, APP_TYPE_PWA } from 'constants/app-name';
import { ExperimentsKeys } from 'constants/experiments';

import { useStyles } from './styles';

export const Congrats = () => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const { pageConfiguration } = useContext(BaseContext);

    disableBrowserBackButton(window.location.href);

    const pwaNativeSplitVariationExp = localStorage.getItem(ExperimentsKeys.pwaNativeSplitVariation) === '1';
    const userApp = pwaNativeSplitVariationExp ? APP_TYPE_PWA : APP_TYPE_APP;

    useEffect(() => {
        setTimeout(() => pwaNativeSplitVariationExp ? handleRedirectToPwa() : pageConfiguration.handleOnPageComplete({ userApp }), 4000);
    }, [navigate]);

    const { content, title, text } = useStyles();

    return (
        <div>
            <div className={content}>
                <h1 className={title}>{t('congrats-title')}</h1>
                <h2 className={text}>
                    <Trans i18nKey="congrats-description" />
                </h2>
            </div>
        </div>
    );
};
