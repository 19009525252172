import React from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, useImagePreloadingService } from '@ruby-labs/ui-core-kit';

import { useDesktopMediaWatcher } from 'hooks/use-desktop-media-watcher.hook';

import { useStyles } from './styles';

export const AchievementWithAble = () => {
    const { t } = useTranslation();

    const { block } = useStyles();

    const isDesktop = useDesktopMediaWatcher();

    const { getImages } = useImagePreloadingService();

    const pageImages = getImages([
        'svg/graphs/achievement-with-able/graph-desktop.svg',
        'svg/graphs/achievement-with-able/graph-mobile.svg',
    ]);

    return (
        <div className={block}>

            <Heading title={t('graphs-title-1')} subtitle={t('graphs-description-1')}/>

            {isDesktop && <img src={pageImages[0]} alt="Graph" />}
            {!isDesktop && <img src={pageImages[1]} alt="Graph" />}
        </div>
    )
};
