import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, IMultiChoiceButtonOption, MultiChoice } from '@ruby-labs/ui-core-kit';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';

import { NextButton } from 'components/next-button';
import { FloatedButton } from 'components/floated-button';

import { useStyles } from './styles';

export const BodyPart = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const { buttonWrapper } = useStyles();

    const pageKey = LocalStorageKeys[RoutePath.BodyPart];

    const { pageValue, setPageValue } = useLocalStorage({
        key: pageKey,
        defaultValue: [],
    });

    const multiChoices: IMultiChoiceButtonOption[] = [
        {
            value: 'all',
            label: t('body-part-option-full-body'),
            image: 'webp/body-part/full.webp',
        },
        {
            value: 'chest',
            label: t('body-part-option-chest'),
            image: 'webp/body-part/chest.webp',
        },
        {
            value: 'arms',
            label: t('body-part-option-arms'),
            image: 'webp/body-part/arm.webp',
        },
        {
            value: 'abs',
            label: t('body-part-option-abs'),
            image: 'webp/body-part/abs.webp',
        },
        {
            value: 'legs',
            label: t('body-part-option-legs'),
            image: 'webp/body-part/legs.webp',
        },
        {
            value: 'back',
            label: t('body-part-option-back'),
            image: 'webp/body-part/backpart.webp',
        },
    ];

    const handleClick = () => {
        saveAnswers({
            key: pageKey,
            itemQuestion: { question: t('body-part-title'), value: pageValue.map((o: IMultiChoiceButtonOption) => o.label) },
        });

        pageConfiguration.handleOnPageComplete(pageValue.map((o: IMultiChoiceButtonOption) => o.value));
    };

    return (
        <>
            <Heading title={t('body-part-title')} subtitle={t('body-part-subtitle')} />

            <MultiChoice
                options={multiChoices}
                deselectWhenValues={['all']}
                disabledWhenValues={['all']}
                selectedOptions={pageValue}
                onSelect={setPageValue}
                buttonWrapperClass={buttonWrapper} />

            <FloatedButton floated={pageValue.length} withGradient>
                <NextButton onClick={handleClick} disabled={!pageValue.length} />
            </FloatedButton>
        </>
    );
};
