import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading } from '@ruby-labs/ui-core-kit';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';

import { NextButton } from 'components/next-button';
import { RangeSlider } from 'components/range-slider';

export const StressLevel = () => {
    const { t } = useTranslation();

    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const pageKey = LocalStorageKeys[RoutePath.StressLevel];

    const { pageValue: level, setPageValue: setLevel }: any = useLocalStorage({
        key: pageKey,
        defaultValue: 3,
    });

    const levelInfo = [
        { level: 1, label: t('stress-level-option-very-low') },
        { level: 2, label: t('stress-level-option-low') },
        { level: 3, label: t('stress-level-option-moderate') },
        { level: 4, label: t('stress-level-option-high') },
        { level: 5, label: t('stress-level-option-very-high') },
    ];

    const handleClick = () => {
        const value = levelInfo[level - 1];

        saveAnswers({
            key: pageKey,
            itemQuestion: { question: t('stress-level-title'), value: `${value.level} - ${value.label}` },
        });

        pageConfiguration.handleOnPageComplete(value.level);
    };

    return (
        <>
            <Heading title={t('stress-level-title')} subtitle={t('stress-level-subtitle')} />

            <RangeSlider
                value={level}
                min={1}
                max={5}
                popupInfo={levelInfo.map(v => v.label)}
                headInfo={[levelInfo[0].level, levelInfo[levelInfo.length - 1].level]}
                footerInfo={[levelInfo[0].label, levelInfo[levelInfo.length - 1].label]}
                onChange={setLevel} />

            <NextButton onClick={handleClick} />
        </>
    );
};
