import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    fishImage: {
        width: '100%',
        maxWidth: '560px'
    },
    logoImage: {
        height: '32px'
    }
});
