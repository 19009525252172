import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useImagePreloadingService } from '@ruby-labs/ui-core-kit';

import { NextButton } from 'components/next-button';
import { ModalWindow } from 'components/modal-window';

import { useStyles } from './styles';

interface SkipTrialModalWindowProps {
    showSkipTrialModalWindow: boolean;
    upgradePlanMonthsCount: number;
    handleSkipClick: () => void;
    handleUpgradeClick: () => void;
}

export const SkipTrialModalWindow: FC<SkipTrialModalWindowProps> = (
    { showSkipTrialModalWindow, upgradePlanMonthsCount, handleSkipClick, handleUpgradeClick }
    ): JSX.Element => {
    const { t } = useTranslation();

    const { modalWrapper, modal, title, boldText, subtitle, listItemWrapper, listItemIcon, listItemDescription,
        infoWrapper, infoText, buttonsWrapper, skipButtonWrapper, skipButton, startButtonWrapper, startButton } = useStyles();

    const { getImages } = useImagePreloadingService();

    const pageImages = getImages([
        'svg/upgrade-plan/skip-trial-modal-window/weights.svg', 'svg/upgrade-plan/skip-trial-modal-window/unlimited.svg',
        'svg/upgrade-plan/skip-trial-modal-window/cutlery.svg', 'svg/upgrade-plan/skip-trial-modal-window/graph.svg'
    ]);

    return (
        <>
        {showSkipTrialModalWindow &&
            <ModalWindow wrapperClassName={modalWrapper} modalWindowFormClassName={modal} hasCloseIcon={false}>
                <div>
                    <h2 className={title}>
                        <Trans i18nKey='upgrade-plan-skip-trial-modal-window-title' components={[<em className={boldText}></em>]}/>
                    </h2>

                    <h3 className={subtitle}>
                        <Trans i18nKey='upgrade-plan-skip-trial-modal-window-subtitle' />
                    </h3>

                    <div>
                        <div className={listItemWrapper}>
                            <img className={listItemIcon} src={pageImages[0]} alt='Weights' />
                            <span className={listItemDescription}>
                                <Trans i18nKey='upgrade-plan-modal-what-you-will-get-option-1' />
                            </span>
                        </div>

                        <div className={listItemWrapper}>
                            <img className={listItemIcon} src={pageImages[1]} alt='Coach' />
                            <span className={listItemDescription}>
                                <Trans i18nKey='upgrade-plan-modal-what-you-will-get-option-2' />
                            </span>
                        </div>

                        <div className={listItemWrapper}>
                            <img className={listItemIcon} src={pageImages[2]} alt='Cutlery' />
                            <span className={listItemDescription}>
                                <Trans i18nKey='upgrade-plan-modal-what-you-will-get-option-3' />
                            </span>
                        </div>

                        <div className={listItemWrapper}>
                            <img className={listItemIcon} src={pageImages[3]} alt='Graph' />
                            <span className={listItemDescription}>
                                <Trans i18nKey='upgrade-plan-modal-what-you-will-get-option-4' />
                            </span>
                        </div>
                    </div>
                </div>

                <div className={infoWrapper}>
                    <span className={infoText}>
                        <Trans i18nKey='upgrade-plan-skip-trial-modal-window-info-text' values={{
                            monthsCountToReachGoalWeight: upgradePlanMonthsCount,
                            count: upgradePlanMonthsCount
                        }} />
                    </span>
                </div>

                <div className={buttonsWrapper}>
                    <NextButton
                        typographyText={t('upgrade-plan-skip-trial-modal-window-button-skip')}
                        buttonWrapperClassName={skipButtonWrapper}
                        className={skipButton}
                        onClick={handleSkipClick} />

                    <NextButton
                        typographyText={t('upgrade-plan-skip-trial-modal-window-button-start', { upgradePlanMonthsCount })}
                        buttonWrapperClassName={startButtonWrapper}
                        className={startButton}
                        onClick={handleUpgradeClick} />
                </div>
            </ModalWindow>}
        </>
    );
};
