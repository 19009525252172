import { IMultiChoiceButtonOption } from '@ruby-labs/ui-core-kit';

// export const getMultiChoiceButtonSelectOptions = (pageValue: string[], multiChoices: MultiChoiceType[]) => {
//     return pageValue.map((item: string) => {
//         const currentOption = multiChoices.find((option) => option.key === item);
//
//         return currentOption?.key === 'other' ? `${currentOption.value}: ${currentOption?.userAnswer?.toString()?.trim()}` : currentOption?.value;
//     }) as string[];
// };

export const getMultiChoiceSelected = (selectedOptions: IMultiChoiceButtonOption[]) => {
    const values = selectedOptions.map(o => o.value);
    const labels = selectedOptions.map(o => {

        if (o.userAnswer) {
            return `${o.label}: ${o.userAnswer?.toString()?.trim()}`;
        }
        return o.label;
    });

    return { values, labels };
};
