import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    questionTitle: {
        fontSize: '18px',
        lineHeight: '24px',
        margin: '0 0 32px',
        textAlign: 'center',
    },
    buttonsWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        gap: '16px',
        '& > div': {
            width: '100%'
        },
        '& button': {
            minWidth: 'unset',
            flexBasis: '90%'
        }
    }
});
