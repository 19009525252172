import { RoutePath } from 'routes/route-path.constant';
import { LocalStorageKeys, MultipleLocalStorageKeys } from 'constants/local-storage';
import { CUSTOMER_ID, PAYMENT_METHODS } from 'constants/payment';
// import { UserVerificationKeys } from 'constants/user-verification';

export type PageType = {
    customWrapper?: boolean,
    scrollableContent?: boolean,
    header: {
        email: boolean,
        logo: boolean,
        progress: {
            percents: number | null,
            label: string | null
        } | null
    },
    route: {
        next: (data?: any) => string,
    },
    events: {
        onEnter?: (data?: any) => string,
        onLeave?: (data?: any) => string,
    },
    // @description: string - required & string[] - any of the item required
    requiredKeys?: (string | string[])[],
    footerAtTheBottom?: boolean,
};

export type PageConfigurationType = {
    [key: string]: PageType;
};

export const DEFAULT_PAGES_CONFIGURATION: PageConfigurationType = {
    [RoutePath.StartQuiz]: {
        header: {
            email: false,
            logo: false,
            progress: null
        },
        route: {
            next: () => RoutePath.DesiredLook
        },
        events: {
            onLeave: () => 'MenStartQuizClicked',
        }
    },
    [RoutePath.DesiredLook]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 8,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.BodyPart
        },
        events: {
            onLeave: () => 'MenDesiredLookClicked',
        }
    },
    [RoutePath.BodyPart]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 16,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.Motivation
        },
        events: {
            onLeave: () => 'MenBodyPartClicked',
        }
    },
    [RoutePath.Motivation]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 24,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.SpecificEvent
        },
        events: {
            onLeave: () => 'MenMotivationClicked',
        }
    },
    [RoutePath.SpecificEvent]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 26,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.BodyAge
        },
        events: {
            onLeave: () => 'MenSpecificEventClicked',
        }
    },
    [RoutePath.BodyAge]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 28,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.BodyFat
        },
        events: {
            onLeave: () => 'MenAgeClicked',
        }
    },
    [RoutePath.BodyFat]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 30,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.BodyHeight
        },
        events: {
            onLeave: () => 'MenFatClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge]
        ]
    },
    [RoutePath.BodyHeight]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 32,
                label: '',
            },
        },
        route: {
            next: () => RoutePath.BodyWeight,
        },
        events: {
            onLeave: () => 'MenHeightClicked'
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyFat]
        ]
    },
    [RoutePath.BodyWeight]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 34,
                label: '',
            },
        },
        route: {
            next: () => RoutePath.BodyIdealWeight,
        },
        events: {
            onLeave: () => 'MenWeightClicked'
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyFat],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
        ]
    },
    [RoutePath.BodyIdealWeight]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 36,
                label: '',
            },
        },
        route: {
            next: () => RoutePath.BodyHealthSummary,
        },
        events: {
            onLeave: () => 'MenGoalWeightClicked'
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyFat],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs]
        ]
    },
    [RoutePath.BodyHealthSummary]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 38,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.FitnessLevel
        },
        events: {
            onLeave: () => 'MenBodyHealthSummaryClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyFat],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs]
        ]
    },
    [RoutePath.FitnessLevel]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 40,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.Workouts
        },
        events: {
            onLeave: () => 'MenFitnessLevelClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyFat],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs]
        ]

    },
    [RoutePath.Workouts]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 41,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.WorkoutsTip
        },
        events: {
            onLeave: () => 'MenWorkoutsClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyFat],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs]
        ]
    },
    [RoutePath.WorkoutsTip]: {
        customWrapper: true,
        header: {
            email: false,
            logo: true,
            progress: null
        },
        route: {
            next: () => RoutePath.PhysicalLimitations
        },
        events: {
            onLeave: () => 'MenWorkoutsTipClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyFat],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs]
        ]
    },
    [RoutePath.PhysicalLimitations]: {
        scrollableContent: true,
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 42,
                label: '',
            }
        },
        route: {
            next: ({ value }) => value[0] === 'none' ? RoutePath.PushUps : RoutePath.PhysicalLimitationsTip,
        },
        events: {
            onLeave: () => 'MenPhysicalLimitationsClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyFat],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs]
        ]
    },
    [RoutePath.PhysicalLimitationsTip]: {
        customWrapper: true,
        header: {
            email: false,
            logo: true,
            progress: null
        },
        route: {
            next: () => RoutePath.PushUps,
        },
        events: {
            onLeave: () => 'MenPhysicalLimitationsTipClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyFat],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs]
        ]
    },
    [RoutePath.PushUps]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 42,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.PullUps
        },
        events: {
            onLeave: () => 'MenPushUpsClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyFat],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs]
        ]
    },
    [RoutePath.PullUps]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 43,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.SleepHours
        },
        events: {
            onLeave: () => 'MenPullUpsClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyFat],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs]
        ]
    },
    [RoutePath.SleepHours]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 44,
                label: ''
            }
        },
        route: {
            next: () => RoutePath.SleepHoursTip,
        },
        events: {
            onLeave: () => 'MenSleepHoursClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyFat],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs]
        ]
    },
    [RoutePath.SleepHoursTip]: {
        customWrapper: true,
        header: {
            email: false,
            logo: true,
            progress: null
        },
        route: {
            next: () => RoutePath.StressLevel,
        },
        events: {
            onLeave: () => 'MenSleepHoursTipClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyFat],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs]
        ]
    },
    [RoutePath.StressLevel]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 45,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.StressLevelTip
        },
        events: {
            onLeave: () => 'MenStressLevelClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyFat],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs]
        ]
    },
    [RoutePath.StressLevelTip]: {
        customWrapper: true,
        header: {
            email: false,
            logo: true,
            progress: null
        },
        route: {
            next: () => RoutePath.AtIdealShape,
        },
        events: {
            onLeave: () => 'MenStressLevelTipClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyFat],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs]
        ]
    },
    [RoutePath.AtIdealShape]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 46,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.PastBlockers
        },
        events: {
            onLeave: () => 'MenAtIdealShapeClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyFat],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs]
        ]
    },
    [RoutePath.PastBlockers]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 47,
                label: '',
            }
        },
        route: {
            next: (answer) => answer[0] === 'none' ? RoutePath.FridgeFood : RoutePath.PastBlockersTip
        },
        events: {
            onLeave: () => 'MenPastBlockersClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyFat],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs]
        ]
    },
    [RoutePath.PastBlockersTip]: {
        customWrapper: true,
        header: {
            email: false,
            logo: true,
            progress: null
        },
        route: {
            next: () => RoutePath.FridgeFood,
        },
        events: {
            onLeave: () => 'MenPastBlockersTipClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyFat],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs]
        ]
    },
    [RoutePath.FridgeFood]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 50,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.GlassesWater
        },
        events: {
            onLeave: () => 'MenFridgeFoodClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyFat],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs]
        ]
    },
    [RoutePath.GlassesWater]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 51,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.GlassesWaterTip
        },
        events: {
            onLeave: () => 'MenGlassesWaterClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyFat],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs]
        ]
    },
    [RoutePath.GlassesWaterTip]: {
        customWrapper: true,
        header: {
            email: false,
            logo: true,
            progress: null
        },
        route: {
            next: () => RoutePath.ConsumedItems,
        },
        events: {
            onLeave: () => 'MenGlassesWaterTipClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyFat],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs]
        ]
    },
    [RoutePath.ConsumedItems]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 52,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.EatTimes
        },
        events: {
            onLeave: () => 'MenConsumedItemsClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyFat],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs]
        ]
    },
    [RoutePath.EatTimes]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 55,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.SingleMeal
        },
        events: {
            onLeave: () => 'MenEatTimesClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyFat],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs]
        ]
    },
    [RoutePath.SingleMeal]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 57,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.SpecificDiet
        },
        events: {
            onLeave: () => 'MenSingleMealClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyFat],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs]
        ]
    },
    [RoutePath.SpecificDiet]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 60,
                label: '',
            }
        },
        route: {
            next: ({ value }) => value.toLowerCase().startsWith('not') ? RoutePath.IntermittentFasting : RoutePath.SpecificDietTip
        },
        events: {
            onLeave: () => 'MenSpecificDietClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyFat],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs]
        ]
    },
    [RoutePath.SpecificDietTip]: {
        customWrapper: true,
        header: {
            email: false,
            logo: true,
            progress: null
        },
        route: {
            next: () => RoutePath.IntermittentFasting,
        },
        events: {
            onLeave: () => 'MenSpecificDietTipClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyFat],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs]
        ]
    },
    [RoutePath.IntermittentFasting]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 60,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.IntermittentFastingTip
        },
        events: {
            onLeave: () => 'MenIntermittentFastingClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyFat],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs]
        ]
    },
    [RoutePath.IntermittentFastingTip]: {
        customWrapper: true,
        header: {
            email: false,
            logo: true,
            progress: null
        },
        route: {
            next: () => RoutePath.WorkoutTimes,
        },
        events: {
            onLeave: () => 'MenIntermittentFastingTipClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyFat],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs]
        ]
    },
    [RoutePath.WorkoutTimes]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 62,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.WorkoutLong
        },
        events: {
            onLeave: () => 'MenWorkoutTimesClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyFat],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs]
        ]
    },
    [RoutePath.WorkoutLong]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 65,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.WorkoutPlan
        },
        events: {
            onLeave: () => 'MenWorkoutLongClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyFat],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs]
        ]
    },
    [RoutePath.WorkoutPlan]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 67,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.WorkoutPlace
        },
        events: {
            onLeave: () => 'MenWorkoutPlanClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyFat],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs]
        ]
    },
    [RoutePath.WorkoutPlace]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 70,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.WorkoutEquipment
        },
        events: {
            onLeave: () => 'MenWorkoutPlaceClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyFat],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs]
        ]
    },
    [RoutePath.WorkoutEquipment]: {
        header: {
            email: false,
            logo: true,
            progress: {
                percents: 72,
                label: '',
            }
        },
        route: {
            next: () => RoutePath.PlanLoader
        },
        events: {
            onLeave: () => 'MenWorkoutEquipmentClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyFat],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs]
        ]
    },
    [RoutePath.PlanLoader]: {
        customWrapper: true,
        header: {
            email: false,
            logo: false,
            progress: null
        },
        route: {
            next: () => RoutePath.Email
        },
        events: {
            onEnter: () => 'MenPlanLoaderViewed',
            onLeave: () => 'MenPlanLoaderCompleted',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyFat],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs]
        ]
    },
    [RoutePath.Email]: {
        header: {
            email: false,
            logo: true,
            progress:  {
                percents: 80,
                label: '',
            },
        },
        route: {
            next: (data) => {
                if (data?.isUserActive) {
                    return RoutePath.Welcome;
                }

                return data?.hideNewsOffer ? RoutePath.TrialPay : RoutePath.NewsOffer;
            }
        },
        events: {
            onLeave: () => 'MenUserEmailClicked'
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs]
        ]
    },
    [RoutePath.NewsOffer]: {
        header: {
            email: true,
            logo: false,
            progress:  {
                percents: 85,
                label: '',
            },
        },
        route: {
            next: () => RoutePath.TrialPay,
        },
        events: {
            onLeave: () => 'MenNewsOffersClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email],
        ]
    },
    [RoutePath.TrialPay]: {
        customWrapper: true,
        header: {
            email: true,
            logo: false,
            progress: null,
        },
        route: {
            next: () => RoutePath.Paywall,
        },
        events: {
            onEnter: () => 'MenTrialPickerViewed',
            onLeave: () => 'MenTrialPickerClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email],
        ]
    },
    [RoutePath.Paywall]: {
        customWrapper: true,
        header: {
            email: true,
            logo: false,
            progress: null
        },
        route: {
            next: (data: any) => {
                // @description: Redirect to upsell screens only users that don't pay with PayPal
                const isPayPal = (data.paymentMethod)?.toLowerCase() === (PAYMENT_METHODS.payPal)?.toLowerCase();

                return data.mg_add_plan_upgrade_screen && !isPayPal ? RoutePath.UpgradePlan : RoutePath.Congrats;
            },
        },
        events: {
            onEnter: () => 'MenPaywallViewed',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.BodyAge],
            LocalStorageKeys[RoutePath.BodyIdealWeight],
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            LocalStorageKeys[RoutePath.Email],
        ],
        footerAtTheBottom: true
    },
    [RoutePath.UpgradePlan]: {
        customWrapper: true,
        header: {
            email: true,
            logo: false,
            progress: null
        },
        route: {
            next: () => RoutePath.Congrats,
        },
        events: {},
        requiredKeys: [
            LocalStorageKeys[RoutePath.Email],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem, LocalStorageKeys[RoutePath.UpgradePlan]], // User went through funnel or direct redirect
            MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
            [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs],
            CUSTOMER_ID,
        ]
    },
    [RoutePath.Congrats]: {
        header: {
            email: true,
            logo: false,
            progress: null
        },
        route: {
            next: () => RoutePath.Welcome,
        },
        events: {
            onEnter: () => 'MenCongratsViewed',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.Email],
            CUSTOMER_ID
        ]
    },
    [RoutePath.Welcome]: {
        customWrapper: true,
        header: {
            email: true,
            logo: false,
            progress: null
        },
        route: {
            next: () => RoutePath.Welcome,
        },
        events: {
            onLeave: () => 'MenDownloadButtonClicked',
        },
        requiredKeys: [
            LocalStorageKeys[RoutePath.Email],
            CUSTOMER_ID
        ]
    }
};
