import React, { useContext } from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { Heading, ISingleChoiceButtonOption, SingleChoice } from '@ruby-labs/ui-core-kit';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';

export enum WORKOUT_LONG_TIME {
    FIFTEEN = '15-minutes',
    THIRTY = 'half-hour',
    FORTYFIVE = 'quarter-hour',
    HOUR = 'hour',
    FLEXIBLE = 'flexible'
}

export const WORKOUT_LONG_ANSWERS: ISingleChoiceButtonOption[] = [
    {
        value: WORKOUT_LONG_TIME.FIFTEEN,
        label: i18n.t('workout-long-option-15-minutes'),
    },
    {
        value: WORKOUT_LONG_TIME.THIRTY,
        label: i18n.t('workout-long-option-half-hour'),
    },
    {
        value: WORKOUT_LONG_TIME.FORTYFIVE,
        label: i18n.t('workout-long-option-quarter-hour'),
    },
    {
        value: WORKOUT_LONG_TIME.HOUR,
        label: i18n.t('workout-long-option-hour'),
    },
    {
        value: WORKOUT_LONG_TIME.FLEXIBLE,
        label: i18n.t('workout-long-option-flexible'),
    }
];

export const WorkoutLong = () => {
    const { t } = useTranslation();
    const { pageConfiguration } = useContext(BaseContext);

    const saveAnswers = useSaveAnswers();

    const pageKey = LocalStorageKeys[RoutePath.WorkoutLong];

    const { pageValue, setPageValue }: any = useLocalStorage({
        key: pageKey,
        defaultValue: '',
    });

    const handleClick = (option: ISingleChoiceButtonOption) => {
        saveAnswers({
            key: pageKey,
            itemQuestion: { question: t('workout-long-title'), value: option.label },
        });
        setPageValue(option);

        pageConfiguration.handleOnPageComplete(option.value);
    };

    return (
        <>
            <Heading title={t('workout-long-title')} />

            <SingleChoice
                options={WORKOUT_LONG_ANSWERS}
                selectedOption={pageValue}
                onSelect={handleClick} />
        </>
    );
};
