import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFeatureValue } from '@growthbook/growthbook-react';

import {
    Heading,
    ISingleChoiceButtonOption,
    SingleChoiceButton, SingleChoicesButtonLabel,
    SingleChoicesButtonWrapper, useImagePreloadingService
} from '@ruby-labs/ui-core-kit';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { ExperimentsKeys } from 'constants/experiments';

import { useStyles } from './styles';

export enum DESIRED_LOOK { SLIM = 'slim', SHREDDED = 'shredded', BODYBUILDER = 'bodybuilder' }

export const DEFAULT_DESIRED_LOOK = { value: DESIRED_LOOK.SLIM };

export const DesiredLook = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { choiceButtonWrapper } = useStyles();

    const { pageConfiguration } = useContext(BaseContext);

    const { getImageByUrl } = useImagePreloadingService();

    const pageKey = LocalStorageKeys[RoutePath.DesiredLook];

    const { pageValue, setPageValue }: any = useLocalStorage({
        key: pageKey,
        defaultValue: '',
    });

    const options = [
        {
            value: DESIRED_LOOK.SLIM,
            label: t('desired-look-option-slim'),
            image: getImageByUrl('png/desired-look/slim.png')
        },
        {
            value: DESIRED_LOOK.SHREDDED,
            label: t('desired-look-option-shredded'),
            image: getImageByUrl('png/desired-look/shredded.png')
        },
        {
            value: DESIRED_LOOK.BODYBUILDER,
            label: t('desired-look-option-bodybuilder'),
            image: getImageByUrl('png/desired-look/bodybuilder.png')
        },
    ];

    const handleClick = (option: ISingleChoiceButtonOption) => {
        saveAnswers({
            key: pageKey,
            itemQuestion: { question: t('desired-look-title'), value: t(option.label) },
        });
        setPageValue(option);

        pageConfiguration.handleOnPageComplete(option.value);
    };

    const mg_delete_motivation_and_specific_event_screens = useFeatureValue(ExperimentsKeys.mg_delete_motivation_and_specific_event_screens, '0');

    useEffect(() => {
        localStorage.setItem(ExperimentsKeys.mg_delete_motivation_and_specific_event_screens, mg_delete_motivation_and_specific_event_screens);
    }, [mg_delete_motivation_and_specific_event_screens]);

    return (
        <>
            <Heading title={t('desired-look-title')} />

            <SingleChoicesButtonWrapper>
                {options.map((option) => (
                    <SingleChoiceButton
                        key={option.value}
                        option={option}
                        selected={option.value === pageValue.value}
                        onClick={() => handleClick(option)}
                        className={choiceButtonWrapper}>
                        <SingleChoicesButtonLabel label={option.label} />
                        <img src={option.image} alt='' />
                    </SingleChoiceButton>
                ))}
            </SingleChoicesButtonWrapper>
        </>
    );
};
