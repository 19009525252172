import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, ISingleChoiceButtonOption, SingleChoice } from '@ruby-labs/ui-core-kit';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';

export const GlassesWater = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const pageKey = LocalStorageKeys[RoutePath.GlassesWater];

    const { pageValue, setPageValue }: any = useLocalStorage({
        key: pageKey,
        defaultValue: '',
    });

    const { pageConfiguration } = useContext(BaseContext);

    const handleClick = (option: ISingleChoiceButtonOption) => {
        saveAnswers({
            key: pageKey,
            itemQuestion: { question: t('glasses-water-title'), value: option.label },
        });
        setPageValue(option);

        pageConfiguration.handleOnPageComplete(option.value);
    };

    const options: ISingleChoiceButtonOption[] = [
        {
            value: 'coffee-tea',
            label: t('glasses-water-option-coffee-tea'),
        },
        {
            value: 'up-2-glasses',
            label: t('glasses-water-option-up-2-glasses'),
        },
        {
            value: 'up-6-glasses',
            label: t('glasses-water-option-up-6-glasses'),
        },
        {
            value: 'up-10-glasses',
            label: t('glasses-water-option-up-10-glasses'),
        },
        {
            value: 'more-10-glasses',
            label: t('glasses-water-option-more-10-glasses'),
        },
    ];

    return (
        <>
            <Heading title={t('glasses-water-title')} />

            <SingleChoice
                options={options}
                selectedOption={pageValue}
                onSelect={handleClick} />
        </>
    );
};
