import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles(() => ({
    logosWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        gap: '32px',
        margin: '24px 0'
    },
    welcomeDesktopInfoWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '34px',
        padding: '0 16px',
    },
    welcomeDesktopTitle: {
        fontSize: '28px',
        lineHeight: '33px',
        margin: '80px 0 16px',
        textAlign: 'center',
    },
    welcomeDesktopSubtitle: {
        fontSize: '16px',
        lineHeight: '20px',
        color: '#E6E6E6',
        margin: '8px auto 40px',
        maxWidth: '544px'
    },
    qrCodeImage: {
       width: '200px',
       height: '200px',
       alignSelf: 'flex-start',
       marginLeft: '20px',
    },
    listWrapper: {
        maxWidth: '913px',
    },
    list: {
        listStyle: 'decimal',
        listStylePosition: 'outside',
    },
    listTitle: {
        fontSize: '20px',
        lineHeight: '28px',
        textAlign: 'left',
        margin: '0 0 16px',
    },
    listItem: {
        fontSize: '20px',
        lineHeight: '28px',
        textAlign: 'left',
        wordBreak: 'break-word',
        margin: '0',

        '& li': {
            marginLeft: '28px',
        },

        '@media (max-width: 500px)' : {
            '& li': {
                marginLeft: '36px',
            }
        },

        '& a': {
            color: '#1375D6',
            textDecoration: 'underline',
            textUnderlineOffset: '4px',
        }
    },
    '@media screen and (min-width: 768px)': {
        logosWrapper: {
            margin: '80px 0'
        }
    }
}));
