import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    bodyImageWrapper: {
        margin: '32px 0',
        display: 'flex',
        justifyContent: 'center',
        minHeight: '246px'
    },
    title: {
        textAlign: 'center',
        margin: '32px 0',
    },
    bodyImage: {
        height: '246px',
    },
    description: {
        margin: '32px 0 12px 0',
    },
    '@media only screen and (min-width: 768px)' : {
        bodyImageWrapper: {
            margin: '80px 0 40px',
        },
        title: {
            margin: '40px 0',
        }
    }
});
