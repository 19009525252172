import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading } from '@ruby-labs/ui-core-kit';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';

import { NextButton } from 'components/next-button';
import { RangeSlider } from 'components/range-slider';
import { TipInfoBlock } from 'components/tip-info-block';

import { useStyles } from './styles';

export const FITNESS_LEVEL_INFO = [
    { level: 1, label: 'fitness-level-option-sedentary', tip: 'fitness-level-tip-sedentary' },
    { level: 2, label: 'fitness-level-option-beginner', tip: 'fitness-level-tip-beginner' },
    { level: 3, label: 'fitness-level-option-intermediate', tip: 'fitness-level-tip-intermediate' },
    { level: 4, label: 'fitness-level-option-advanced', tip: 'fitness-level-tip-advanced' },
    { level: 5, label: 'fitness-level-option-elite', tip: 'fitness-level-tip-elite' },
];

export const FitnessLevel = () => {
    const { t } = useTranslation();

    const { description } = useStyles();

    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const pageKey = LocalStorageKeys[RoutePath.FitnessLevel];

    const { pageValue, setPageValue }: any = useLocalStorage({
        key: pageKey,
        defaultValue: 3,
    });

    const handleClick = () => {
        const value = FITNESS_LEVEL_INFO[pageValue - 1];

        saveAnswers({
            key: pageKey,
            itemQuestion: { question: t('fitness-level-title'), value: `${value.level} - ${t(value.label)}` },
        });

        pageConfiguration.handleOnPageComplete(value.level);
    };

    return (
        <>
            <Heading title={t('fitness-level-title')} />

            <RangeSlider
                value={pageValue}
                min={1}
                max={5}
                popupInfo={FITNESS_LEVEL_INFO.map(v => t(v.label))}
                headInfo={[FITNESS_LEVEL_INFO[0].level, FITNESS_LEVEL_INFO[FITNESS_LEVEL_INFO.length - 1].level]}
                footerInfo={[t(FITNESS_LEVEL_INFO[0].label), t(FITNESS_LEVEL_INFO[FITNESS_LEVEL_INFO.length - 1].label)]}
                onChange={setPageValue} />

            <div className={description}>
                <TipInfoBlock info={t(FITNESS_LEVEL_INFO[pageValue - 1].tip)}/>
            </div>

            <NextButton onClick={handleClick} />
        </>
    );
};
