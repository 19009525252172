import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, ISingleChoiceButtonOption, SingleChoice } from '@ruby-labs/ui-core-kit';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';

export const PullUps = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const pageKey = LocalStorageKeys[RoutePath.PullUps];

    const { pageValue, setPageValue }: any = useLocalStorage({
        key: pageKey,
        defaultValue: '',
    });

    const handleClick = (option: ISingleChoiceButtonOption) => {
        saveAnswers({
            key: pageKey,
            itemQuestion: { question: t('pull-ups-title'), value: option.label },
        });
        setPageValue(option);

        pageConfiguration.handleOnPageComplete(option.value);
    };

    const options: ISingleChoiceButtonOption[] = [
        { value: 'none', label: t('pull-ups-option-none') },
        { value: 'little', label: t('pull-ups-option-little') },
        { value: 'normal', label: t('pull-ups-option-normal') },
        { value: 'more', label: t('pull-ups-option-more') },
    ];

    return (
        <>
            <Heading title={t('pull-ups-title')} />

            <SingleChoice
                options={options}
                selectedOption={pageValue}
                onSelect={handleClick} />
        </>
    );
};
