import { ISingleChoiceButtonOption } from '@ruby-labs/ui-core-kit';

export const AGES: ISingleChoiceButtonOption[] = [
    { value: 'underTwenty', label: 'body-age-option-underTwenty' },
    { value: 'twentys', label: 'body-age-option-twenties' },
    { value: 'thirtys', label: 'body-age-option-thirties' },
    { value: 'fortys', label: 'body-age-option-forties' },
    { value: 'fiftys', label: 'body-age-option-fifties' },
    { value: 'sixtyPlus', label: 'body-age-option-sixtyPlus' },
];
