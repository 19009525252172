import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
    ISingleChoiceButtonOption,
    SingleChoiceButton,
    SingleChoicesButtonLabel
} from '@ruby-labs/ui-core-kit';

import { pageConfiguration } from 'containers/index';

import { useLocalStorage } from 'hooks/use-local-storage.hook';
import { useSaveAnswers } from 'hooks/use-save-answers.hook';

import { useStyles } from './styles';

export type PopupData = {
    title: string;
    storageKey: string;
    event: string;
    answers: ISingleChoiceButtonOption[];
}

interface ModalContentProps {
    onChosen?: () => void;
    data: PopupData;
}

export const ModalContent: FC<ModalContentProps> = ({ data, onChosen }) => {
    const saveAnswers = useSaveAnswers();
    const { t } = useTranslation();

    const { questionTitle, buttonsWrapper } = useStyles();

    const { title, answers, storageKey, event } = data;

    const { pageValue, setPageValue } = useLocalStorage({ key: storageKey, defaultValue: '' });

    const handleClick = (option: ISingleChoiceButtonOption) => {
        setPageValue(option.value);

        pageConfiguration.event(event, t(option.label));

        saveAnswers({
            key: storageKey,
            itemQuestion: { question: t(title), value: t(option.label) },
        });

        if (onChosen) {
            setTimeout(onChosen, 100);
        }
    };

    return (
        <div>
            <h1 className={questionTitle}>
                {t(title)}
            </h1>

            <div className={buttonsWrapper}>
                {answers.map((option) => (
                    <SingleChoiceButton
                        key={option.value}
                        option={option}
                        selected={option.value === pageValue.value}
                        onClick={() => handleClick(option)}>
                        <SingleChoicesButtonLabel label={t(option.label)} />
                    </SingleChoiceButton>
                ))}
            </div>
        </div>
    );
};
