import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Heading } from '@ruby-labs/ui-core-kit';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useUTMSearch } from 'hooks/use-encharge-search.hook';

import { NextButton } from 'components/next-button';

// import fish_muscle_gain from 'assets/images/webp/fish_muscle_gain.webp';
// import logo from 'assets/images/svg/logo.svg';

import { useStyles } from './styles';

export const StartQuiz = () => {
    const navigate = useNavigate();
    const { pageConfiguration } = useContext(BaseContext);
    const { t } = useTranslation();

    const { wrapper } = useStyles();

    useEffect(() => {
        const themeColorMeta = window.document.querySelector('meta[name="theme-color"]');

        if (themeColorMeta) {
            themeColorMeta.setAttribute('content', '#010101');
        }

        return () => {
            if (themeColorMeta) {
                themeColorMeta.setAttribute('content', '#1C1C1E');
            }
        };
    }, []);

    const UTMSearch = useUTMSearch();

    if (true) {
        navigate(`../${RoutePath.DesiredLook}${UTMSearch}`);
        return null;
    }

    const handleClick = () => {
        pageConfiguration.handleOnPageComplete();
    };

    return (
        <div className={wrapper}>
            {/*<img className={fishImage} src={fish_muscle_gain} alt='Fish' />*/}
            {/*<img className={logoImage} src={logo} alt='Logo' />*/}

            <Heading title={t('start-quiz-title')} subtitle={t('start-quiz-subtitle')}/>

            <NextButton
                onClick={handleClick}
                typographyText={t('start-quiz-button-next')}
            />
        </div>
    );
};
