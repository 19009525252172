import React, { useContext, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Heading } from '@ruby-labs/ui-core-kit';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';
import { UsersApiProvider } from 'api/users.api';

import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { UserGeolocationKeys } from 'constants/user-geolocation';
import { LocalStorageKeys } from 'constants/local-storage';
import { UserVerificationKeys } from 'constants/user-verification';
import { LinksKeys } from 'constants/links';

import { NextButton } from 'components/next-button';
import { ValidatedInput } from 'components/validated-input';

import { useStyles } from './styles';

export const Email = () => {
    const { t } = useTranslation();

    const { agreementText } = useStyles();

    const { pageConfiguration } = useContext(BaseContext);

    const { pageValue: email, setPageValue: setEmail } = useLocalStorage({
        key: LocalStorageKeys[RoutePath.Email],
        defaultValue: '',
    });

    const isEUCountry = localStorage.getItem(UserGeolocationKeys.isEUCountry);
    let hideNewsOffer = false;

    if (!isEUCountry) {
        hideNewsOffer = true;
        localStorage.setItem(LocalStorageKeys[RoutePath.NewsOffer], 'yes');
    }

    const handleClick = () => {
        UsersApiProvider
            .isUserActive(email)
            .then(({ isUserActive }) => {
                localStorage.setItem(UserVerificationKeys.isUserActive, String(isUserActive));
                pageConfiguration.handleOnPageComplete({ email, hideNewsOffer, isUserActive });
            });
    };

    const [pageError, setPageError] = useState(false);

    const onChange = (e: any) => {
        setEmail(e.target.value);
    };

    const onError = (error: boolean) => {
        setPageError(error);
    };

    const termsEvent = () => pageConfiguration.event('OnboardingStartTermsClicked');
    const privacyEvent = () => pageConfiguration.event('OnboardingStartPrivacyClicked');

    return (
        <>
            <Heading title={t('email-title')} />

            <ValidatedInput
                value={email}
                onChange={onChange}
                onError={onError}
                validationQuery={{
                    type: {
                        value: 'email',
                        errorMessage: t('email-input-error-message'),
                    },
                }}
                label={t('email-input-label')}
            />

            <p className={agreementText}>
                <Trans i18nKey='email-text'>
                    By clicking "See My Results" below you acknowledge that you have read,
                    understood, and accepted Able’s&nbsp;
                    <a
                        href={LinksKeys.TermsAndCondition}
                        target='_blank'
                        rel='noreferrer nofollow'
                        onClick={termsEvent}
                    >
                        Terms of Use
                    </a>{' '}
                    and &nbsp;
                    <a
                        href={LinksKeys.PrivacyPolicy}
                        target='_blank'
                        rel='noreferrer nofollow'
                        onClick={privacyEvent}
                    >
                        Privacy Policy
                    </a>
                    .
                </Trans>
            </p>

            <NextButton
                onClick={handleClick}
                typographyText={t('email-button-next')}
                disabled={!email || pageError}
            />
        </>
    );
};
