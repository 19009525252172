// Desired look
import slimCutted from 'assets/images/png/desired-look/slim.png';
import shreddedCutted from 'assets/images/png/desired-look/shredded.png';
import bodybuilderCutted from 'assets/images/png/desired-look/bodybuilder.png';

// Paywall
import slim from 'assets/images/webp/desired-look/slim.webp';
import shredded from 'assets/images/webp/desired-look/shredded.webp';
import bodybuilder from 'assets/images/webp/desired-look/bodybuilder.webp';

// Body parts
import fullBody from 'assets/images/webp/body-part/full.webp';
import chest from 'assets/images/webp/body-part/chest.webp';
import arm from 'assets/images/webp/body-part/arm.webp';
import abs from 'assets/images/webp/body-part/abs.webp';
import backpart from 'assets/images/webp/body-part/backpart.webp';
import legs from 'assets/images/webp/body-part/legs.webp';

// Body Fat
import five from 'assets/images/webp/body-fat/5-9.webp';
import ten from 'assets/images/webp/body-fat/10-14.webp';
import fifteen from 'assets/images/webp/body-fat/15-19.webp';
import twenty from 'assets/images/webp/body-fat/20-24.webp';
import twentyfive from 'assets/images/webp/body-fat/25-29.webp';
import thirty from 'assets/images/webp/body-fat/30-34.webp';
import thirtyfive from 'assets/images/webp/body-fat/35-39.webp';
import forty from 'assets/images/webp/body-fat/40.webp';

// Reinforcement icons
import apple from 'assets/images/svg/reinforcement-icons/apple.svg';
import cup from 'assets/images/svg/reinforcement-icons/cup.svg';
import glassWater from 'assets/images/svg/reinforcement-icons/glass-water.svg';
import ladderWithStar from 'assets/images/svg/reinforcement-icons/ladder-with-star.svg';
import medal from 'assets/images/svg/reinforcement-icons/medal.svg';
import mountainWithFlag from 'assets/images/svg/reinforcement-icons/mountain-with-flag.svg';
import plane from 'assets/images/svg/reinforcement-icons/plane.svg';
import star from 'assets/images/svg/reinforcement-icons/star.svg';

// Custom plan
import slim5 from 'assets/images/lottie/custom-plan/slim/5-9.lottie';
import slim10 from 'assets/images/lottie/custom-plan/slim/10-14.lottie';
import slim15 from 'assets/images/lottie/custom-plan/slim/15-19.lottie';
import slim20 from 'assets/images/lottie/custom-plan/slim/20-24.lottie';
import slim25 from 'assets/images/lottie/custom-plan/slim/25-29.lottie';
import slim30 from 'assets/images/lottie/custom-plan/slim/30-34.lottie';
import slim35 from 'assets/images/lottie/custom-plan/slim/35-39.lottie';
import slim40 from 'assets/images/lottie/custom-plan/slim/40.lottie';

import shredded5 from 'assets/images/lottie/custom-plan/shredded/5-9.lottie';
import shredded10 from 'assets/images/lottie/custom-plan/shredded/10-14.lottie';
import shredded15 from 'assets/images/lottie/custom-plan/shredded/15-19.lottie';
import shredded20 from 'assets/images/lottie/custom-plan/shredded/20-24.lottie';
import shredded25 from 'assets/images/lottie/custom-plan/shredded/25-29.lottie';
import shredded30 from 'assets/images/lottie/custom-plan/shredded/30-34.lottie';
import shredded35 from 'assets/images/lottie/custom-plan/shredded/35-39.lottie';
import shredded40 from 'assets/images/lottie/custom-plan/shredded/40.lottie';

import bodybuilder5 from 'assets/images/lottie/custom-plan/bodybuilder/5-9.lottie';
import bodybuilder10 from 'assets/images/lottie/custom-plan/bodybuilder/10-14.lottie';
import bodybuilder15 from 'assets/images/lottie/custom-plan/bodybuilder/15-19.lottie';
import bodybuilder20 from 'assets/images/lottie/custom-plan/bodybuilder/20-24.lottie';
import bodybuilder25 from 'assets/images/lottie/custom-plan/bodybuilder/25-29.lottie';
import bodybuilder30 from 'assets/images/lottie/custom-plan/bodybuilder/30-34.lottie';
import bodybuilder35 from 'assets/images/lottie/custom-plan/bodybuilder/35-39.lottie';
import bodybuilder40 from 'assets/images/lottie/custom-plan/bodybuilder/40.lottie';

// Subscribe video player icons
import cross from 'assets/images/svg/paywall/video-player/cross.svg';
import play from 'assets/images/svg/paywall/video-player/play.svg';
import prevButton from 'assets/images/svg/paywall/video-customer-reviews/prev-button.svg';
import nextButton from 'assets/images/svg/paywall/video-customer-reviews/next-button.svg';

// Subscribe customer reviews icons
import appsRating from 'assets/images/svg/paywall/customer-reviews/apps-rating.svg';
import forbes from 'assets/images/svg/paywall/customer-reviews/forbes.svg';
import yahoo from 'assets/images/svg/paywall/customer-reviews/yahoo.svg';
import menHealth from 'assets/images/svg/paywall/customer-reviews/men-health.svg';
import verifiedCustomerIcon from 'assets/images/svg/paywall/customer-reviews/verified-customer-icon.svg';

import ableLogoCarrot from 'assets/images/svg/paywall/payment/payment-info/able-logo-carrot.svg';
import guaranteeBadge from 'assets/images/svg/paywall/payment/payment-info/guarantee-badge.svg';
import gift from 'assets/images/svg/paywall/payment/payment-info/gift.svg';

// Graphs
import withAbleGraphDesktop from 'assets/images/svg/graphs/achievement-with-able/graph-desktop.svg';
import withAbleGraphMobile from 'assets/images/svg/graphs/achievement-with-able/graph-mobile.svg';
import cortisolGraphDesktop from 'assets/images/svg/graphs/cortisol-testosterone/graph-desktop.svg';
import cortisolGraphMobile from 'assets/images/svg/graphs/cortisol-testosterone/graph-mobile.svg';

// Upgrade-plan
import commitmentGraph from 'assets/images/svg/upgrade-plan/content/commitment-graph.svg';
import weights from 'assets/images/svg/upgrade-plan/skip-trial-modal-window/weights.svg';
import unlimited from 'assets/images/svg/upgrade-plan/skip-trial-modal-window/unlimited.svg';
import cutlery from 'assets/images/svg/upgrade-plan/skip-trial-modal-window/cutlery.svg';
import graph from 'assets/images/svg/upgrade-plan/skip-trial-modal-window/graph.svg';

export const BackgroundLoadingImages = () => {
    return {
        images: [
            { key:'png/desired-look/slim.png', pathUrl: slimCutted },
            { key:'png/desired-look/shredded.png', pathUrl: shreddedCutted },
            { key:'png/desired-look/bodybuilder.png', pathUrl: bodybuilderCutted },
            { key:'webp/body-part/full.webp', pathUrl: fullBody },
            { key:'webp/body-part/chest.webp', pathUrl: chest },
            { key:'webp/body-part/arm.webp', pathUrl: arm },
            { key:'webp/body-part/abs.webp', pathUrl: abs },
            { key:'webp/body-part/legs.webp', pathUrl: legs },
            { key:'webp/body-part/backpart.webp', pathUrl: backpart },
            { key:'webp/body-fat/5-9.webp', pathUrl: five },
            { key:'webp/body-fat/10-14.webp', pathUrl: ten },
            { key:'webp/body-fat/15-19.webp', pathUrl: fifteen },
            { key:'webp/body-fat/20-24.webp', pathUrl: twenty },
            { key:'webp/body-fat/25-29.webp', pathUrl: twentyfive },
            { key:'webp/body-fat/30-34.webp', pathUrl: thirty },
            { key:'webp/body-fat/35-39.webp', pathUrl: thirtyfive },
            { key:'webp/body-fat/40.webp', pathUrl: forty },
            { key:'svg/reinforcement-icons/apple.svg', pathUrl: apple },
            { key:'svg/reinforcement-icons/cup.svg', pathUrl: cup },
            { key:'svg/reinforcement-icons/glass-water.svg', pathUrl: glassWater },
            { key:'svg/reinforcement-icons/medal.svg', pathUrl: medal },
            { key:'svg/reinforcement-icons/plane.svg', pathUrl: plane },
            { key:'svg/reinforcement-icons/mountain-with-flag.svg', pathUrl: mountainWithFlag },
            { key:'svg/reinforcement-icons/ladder-with-star.svg', pathUrl: ladderWithStar },
            { key:'svg/reinforcement-icons/star.svg', pathUrl: star },

            { key:'lottie/custom-plan/slim/5-9.lottie', pathUrl: slim5 },
            { key:'lottie/custom-plan/slim/10-14.lottie', pathUrl: slim10 },
            { key:'lottie/custom-plan/slim/15-19.lottie', pathUrl: slim15 },
            { key:'lottie/custom-plan/slim/20-24.lottie', pathUrl: slim20 },
            { key:'lottie/custom-plan/slim/25-29.lottie', pathUrl: slim25 },
            { key:'lottie/custom-plan/slim/30-34.lottie', pathUrl: slim30 },
            { key:'lottie/custom-plan/slim/35-39.lottie', pathUrl: slim35 },
            { key:'lottie/custom-plan/slim/40.lottie', pathUrl: slim40 },
            { key:'lottie/custom-plan/shredded/5-9.lottie', pathUrl: shredded5 },
            { key:'lottie/custom-plan/shredded/10-14.lottie', pathUrl: shredded10 },
            { key:'lottie/custom-plan/shredded/15-19.lottie', pathUrl: shredded15 },
            { key:'lottie/custom-plan/shredded/20-24.lottie', pathUrl: shredded20 },
            { key:'lottie/custom-plan/shredded/25-29.lottie', pathUrl: shredded25 },
            { key:'lottie/custom-plan/shredded/30-34.lottie', pathUrl: shredded30 },
            { key:'lottie/custom-plan/shredded/35-39.lottie', pathUrl: shredded35 },
            { key:'lottie/custom-plan/shredded/40.lottie', pathUrl: shredded40 },
            { key:'lottie/custom-plan/bodybuilder/5-9.lottie', pathUrl: bodybuilder5 },
            { key:'lottie/custom-plan/bodybuilder/10-14.lottie', pathUrl: bodybuilder10 },
            { key:'lottie/custom-plan/bodybuilder/15-19.lottie', pathUrl: bodybuilder15 },
            { key:'lottie/custom-plan/bodybuilder/20-24.lottie', pathUrl: bodybuilder20 },
            { key:'lottie/custom-plan/bodybuilder/25-29.lottie', pathUrl: bodybuilder25 },
            { key:'lottie/custom-plan/bodybuilder/30-34.lottie', pathUrl: bodybuilder30 },
            { key:'lottie/custom-plan/bodybuilder/35-39.lottie', pathUrl: bodybuilder35 },
            { key:'lottie/custom-plan/bodybuilder/40.lottie', pathUrl: bodybuilder40 },

            { key:'webp/desired-look/slim.webp', pathUrl: slim },
            { key:'webp/desired-look/shredded.webp', pathUrl: shredded },
            { key:'webp/desired-look/bodybuilder.webp', pathUrl: bodybuilder },

            { key:'svg/paywall/video-player/play.svg', pathUrl: play },
            { key:'svg/paywall/video-player/cross.svg', pathUrl: cross },
            { key:'svg/paywall/video-customer-reviews/prev-button.svg', pathUrl: prevButton },
            { key:'svg/paywall/video-customer-reviews/next-button.svg', pathUrl: nextButton },
            { key:'svg/paywall/customer-reviews/apps-rating.svg', pathUrl: appsRating },
            { key:'svg/paywall/customer-reviews/men-health.svg', pathUrl: menHealth },
            { key:'svg/paywall/customer-reviews/forbes.svg', pathUrl: forbes },
            { key:'svg/paywall/customer-reviews/yahoo.svg', pathUrl: yahoo },
            { key:'svg/paywall/customer-reviews/verified-customer-icon.svg', pathUrl: verifiedCustomerIcon },
            { key:'svg/paywall/payment/payment-info/able-logo-carrot.svg', pathUrl: ableLogoCarrot },
            { key:'svg/paywall/customer-reviews/guarantee-badge.svg', pathUrl: guaranteeBadge },
            { key:'svg/paywall/customer-reviews/gift.svg', pathUrl: gift },

            { key:'svg/graphs/achievement-with-able/graph-desktop.svg', pathUrl: withAbleGraphDesktop },
            { key:'svg/graphs/achievement-with-able/graph-mobile.svg', pathUrl: withAbleGraphMobile },
            { key:'svg/graphs/cortisol-testosterone/graph-desktop.svg', pathUrl: cortisolGraphDesktop },
            { key:'svg/graphs/cortisol-testosterone/graph-mobile.svg', pathUrl: cortisolGraphMobile },

            { key:'svg/upgrade-plan/content/commitment-graph.svg', pathUrl: commitmentGraph },
            { key:'svg/upgrade-plan/skip-trial-modal-window/weights.svg', pathUrl: weights },
            { key:'svg/upgrade-plan/skip-trial-modal-window/unlimited.svg', pathUrl: unlimited },
            { key:'svg/upgrade-plan/skip-trial-modal-window/cutlery.svg', pathUrl: cutlery },
            { key:'svg/upgrade-plan/skip-trial-modal-window/graph.svg', pathUrl: graph },
        ]
    }
};
