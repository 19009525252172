import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, ISingleChoiceButtonOption, SingleChoice } from '@ruby-labs/ui-core-kit';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';

export const PushUps = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const pageKey = LocalStorageKeys[RoutePath.PushUps];

    const { pageValue, setPageValue }: any = useLocalStorage({
        key: pageKey,
        defaultValue: '',
    });

    const handleClick = (option: ISingleChoiceButtonOption) => {
        saveAnswers({
            key: pageKey,
            itemQuestion: { question: t('push-ups-title'), value: option.label },
        });
        setPageValue(option);

        pageConfiguration.handleOnPageComplete(option.value);
    };

    const options: ISingleChoiceButtonOption[] = [
        { value: '10-less', label: t('push-ups-option-10-less') },
        { value: '10-20', label: t('push-ups-option-10-20') },
        { value: '20-30', label: t('push-ups-option-20-30') },
        { value: '30-more', label: t('push-ups-option-30-more') },
    ];

    return (
        <>
            <Heading title={t('push-ups-title')} />

            <SingleChoice
                options={options}
                selectedOption={pageValue}
                onSelect={handleClick} />
        </>
    );
};
