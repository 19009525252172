import React from 'react';
import { useTranslation } from 'react-i18next';
import { useImagePreloadingService } from '@ruby-labs/ui-core-kit';

import { useDesktopMediaWatcher } from 'hooks/use-desktop-media-watcher.hook';

import { useStyles } from './styles';

export const CortisolTestosterone = () => {
    const { t } = useTranslation();

    const { block, title, subtitle, descriptions } = useStyles();

    const isDesktop = useDesktopMediaWatcher();

    const { getImages } = useImagePreloadingService();

    const pageImages = getImages([
        'svg/graphs/cortisol-testosterone/graph-desktop.svg',
        'svg/graphs/cortisol-testosterone/graph-mobile.svg',
    ]);

    return (
        <div className={block}>
            <h1 className={title}>
                {t('graphs-title-2')}
            </h1>

            {isDesktop && <img src={pageImages[0]} alt="Graph" />}
            {!isDesktop && <img src={pageImages[1]} alt="Graph" />}

            <h1 className={subtitle}>
                {t('graphs-subtitle-2')}
            </h1>

            <div className={descriptions}>
                <h2>
                    {t('graphs-description-2')}
                </h2>

                <h2>
                    {t('graphs-description-3')}
                </h2>

                <h2>
                    {t('graphs-description-4')}
                </h2>
            </div>
        </div>
    )
};
