import React, { useContext } from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

import { Heading, ISingleChoiceButtonOption, SingleChoice } from '@ruby-labs/ui-core-kit';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';

export const WORKOUT_TIMES_ANSWERS: ISingleChoiceButtonOption[] = [
    {
        value: '1-2-times',
        label: i18n.t('workout-times-option-1-2-times'),
    },
    {
        value: '3-5-times',
        label: i18n.t('workout-times-option-3-5-times'),
    },
    {
        value: '5-7-times',
        label: i18n.t('workout-times-option-5-7-times'),
    },
];

export const WorkoutTimes = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const pageKey = LocalStorageKeys[RoutePath.WorkoutTimes];

    const { pageValue, setPageValue }: any = useLocalStorage({
        key: pageKey,
        defaultValue: '',
    });

    const { pageConfiguration } = useContext(BaseContext);

    const handleClick = (option: ISingleChoiceButtonOption) => {
        saveAnswers({
            key: pageKey,
            itemQuestion: { question: t('workout-times-title'), value: option.label },
        });
        setPageValue(option);

        pageConfiguration.handleOnPageComplete(option.value);
    };

    return (
        <>
            <Heading title={t('workout-times-title')} />

            <SingleChoice
                options={WORKOUT_TIMES_ANSWERS}
                selectedOption={pageValue}
                onSelect={handleClick} />
        </>
    );
};
