import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {Heading, IMultiChoiceButtonOption, MultiChoice} from '@ruby-labs/ui-core-kit';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';

import { NextButton } from 'components/next-button';
import { FloatedButton } from 'components/floated-button';

import { getMultiChoiceSelected } from 'helpers/get-multichoice-button-select-options';

export const PastBlockers = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const pageKey = LocalStorageKeys[RoutePath.PastBlockers];

    const { pageValue, setPageValue } = useLocalStorage({
        key: pageKey,
        defaultValue: [],
    });

    const options: IMultiChoiceButtonOption[] = [
        {
            value: 'motivation',
            label: t('past-blockers-option-motivation')
        },
        {
            value: 'plan',
            label: t('past-blockers-option-plan')
        },
        {
            value: 'hard',
            label: t('past-blockers-option-hard')
        },
        {
            value: 'time',
            label: t('past-blockers-option-time')
        },
        {
            value: 'coach',
            label: t('past-blockers-option-coach')
        },
        {
            value: 'none',
            label: t('past-blockers-option-none')
        }
    ];

    const handleClick = () => {
        const { values, labels } = getMultiChoiceSelected(pageValue);

        saveAnswers({
            key: pageKey,
            itemQuestion: { question: t('past-blockers-title'), value: labels },
        });

        pageConfiguration.handleOnPageComplete({ value: values });
    };

    return (
        <>
            <Heading title={t('past-blockers-title')} subtitle={t('past-blockers-subtitle')}/>

            <MultiChoice
                options={options}
                disabledWhenValues={['none']}
                deselectWhenValues={['none']}
                selectedOptions={pageValue}
                onSelect={setPageValue} />

            <FloatedButton floated={pageValue.length} withGradient>
                <NextButton onClick={handleClick} disabled={!pageValue.length} />
            </FloatedButton>
        </>
    );
};
