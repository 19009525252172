import { createUseStyles } from 'react-jss'
import { palette } from 'themes/default/palette';

export const useStyles = createUseStyles({
    agreementText: {
        textAlign: 'left',
        fontSize: '14px',
        lineHeight: '18px',
        margin: '16px 0 28px 0',
        color: '#D3D3D3',

        '& a': {
            textDecoration: 'none',
            color: palette.primary
        }
    }
});
