import { createUseStyles } from 'react-jss';

import { ThemeProps } from 'themes';

export const useStyles = createUseStyles(({ typography }: ThemeProps) => ({
    h1: typography.h1,
    h3: typography.h3,
    p: typography.h1, // @TODO: change it
    buttonTypography: typography.button,
    inputStartAdornment: typography.inputStartAdornment,
    inputEndAdornment: typography.inputEndAdornment,
    inputLabel: typography.inputLabel,
    validatedInputError: typography.validatedInputError,
    tipPage: typography.tipPage,
    ratingButtonTypography: typography.ratingButton,
    ratingGradationInfoTypography: typography.ratingGradationInfo,
    marginBottom16: {
        marginBottom: '16px',
    }
}));
