import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, IMultiChoiceButtonOption, MultiChoice } from '@ruby-labs/ui-core-kit';

import { getMultiChoiceSelected } from 'helpers/get-multichoice-button-select-options';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';

import { NextButton } from 'components/next-button';
import { FloatedButton } from 'components/floated-button';

export const WorkoutPlan = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const pageKey = LocalStorageKeys[RoutePath.WorkoutPlan];

    const { pageValue, setPageValue } = useLocalStorage({
        key: pageKey,
        defaultValue: [],
    });

    const { pageValue: userAnswer, setPageValue: setUserAnswer } = useLocalStorage({
        key: `${pageKey}-other-userAnswer`,
        defaultValue: '',
    });

    const options: IMultiChoiceButtonOption[] = [
        {
            value: 'cardio',
            label: t('workout-plan-option-cardio')
        },
        {
            value: 'strength',
            label: t('workout-plan-option-strength')
        },
        {
            value: 'intensity',
            label: t('workout-plan-option-intensity')
        },
        {
            value: 'stretching',
            label: t('workout-plan-option-stretching')
        },
        {
            value: 'other',
            label: t('workout-plan-option-other'),
            placeholder: 'Add your answer here',
            userAnswer, setUserAnswer
        },
    ];

    const handleClick = () => {
        const { values, labels } = getMultiChoiceSelected(pageValue);

        saveAnswers({
            key: pageKey,
            itemQuestion: { question: t('workout-plan-title'), value: labels },
        });

        pageConfiguration.handleOnPageComplete({
            value: values,
            userAnswer: values.includes('other') ? userAnswer?.toString()?.trim() : ''
        });
    };

    return (
        <>
            <Heading title={t('workout-plan-title')} subtitle={t('workout-plan-subtitle')}/>

            <MultiChoice
                options={options}
                disabledWhenValues={['none']}
                deselectWhenValues={['none']}
                selectedOptions={pageValue}
                onSelect={setPageValue} />

            <FloatedButton floated={pageValue.length} withGradient>
                <NextButton onClick={handleClick} disabled={!pageValue.length} />
            </FloatedButton>
        </>
    );
};
