import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    choiceButtonWrapper: {
        '& button': {
            padding: '5px 13px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            maxHeight: 'unset',
            '& img': {
                width: '76px',
                height: '88px',
            }
        },
    }
});
