import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {Heading, ISingleChoiceButtonOption, SingleChoice} from '@ruby-labs/ui-core-kit';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';

import { NextButton } from 'components/next-button';
import { FloatedButton } from 'components/floated-button';

export const SpecificDiet = () => {
    const { t } = useTranslation();
    const { pageConfiguration } = useContext(BaseContext);

    const saveAnswers = useSaveAnswers();

    const pageKey = LocalStorageKeys[RoutePath.SpecificDiet];

    const { pageValue, setPageValue } = useLocalStorage({
        key: pageKey,
        defaultValue: '',
    });

    const { pageValue: userAnswer, setPageValue: setUserAnswer } = useLocalStorage({
        key: `${pageKey}-other-userAnswer`,
        defaultValue: '',
    });

    const options: ISingleChoiceButtonOption[] = [
        { value: 'not', label: t('specific-diet-option-not') },
        { value: 'lowcarb', label: t('specific-diet-option-lowcarb') },
        { value: 'paleo', label: t('specific-diet-option-paleo') },
        { value: 'keto', label: t('specific-diet-option-keto') },
        { value: 'vegetarian', label: t('specific-diet-option-vegetarian') },
        { value: 'vegan', label: t('specific-diet-option-vegan') },
        { value: 'mediterranean', label: t('specific-diet-option-mediterranean') },
        { value: 'other', label: t('specific-diet-option-other'), placeholder: 'Add your answer here', userAnswer, setUserAnswer },
    ];

    const handleNextButtonClick = (option: ISingleChoiceButtonOption) => {
        const _userAnswer = option.value === 'other' ? userAnswer?.toString()?.trim() : '';

        saveAnswers({
            key: pageKey,
            itemQuestion: {
                question: t('specific-diet-title'),
                value: `${option.value} ${_userAnswer}`
            },
        });

        pageConfiguration.handleOnPageComplete({
            value: option.value,
            userAnswer: _userAnswer
        });
    };

    const handleClick = (option: ISingleChoiceButtonOption) => {
        setPageValue(option);

        if (option.value !== 'other') {
            handleNextButtonClick(option);
        } else {
            setTimeout(() => window.scrollTo(0, document.body.scrollHeight), 100);
        }
    };

    return (
        <>
            <Heading title={t('specific-diet-title')} />

            <SingleChoice
                options={options}
                selectedOption={pageValue}
                onSelect={handleClick} />

            {pageValue?.value === 'other' &&
                <FloatedButton withGradient>
                    <NextButton onClick={() => handleNextButtonClick(pageValue)} disabled={false} />
                </FloatedButton>
            }
        </>
    );
};
