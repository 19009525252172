import React, {useContext, useEffect} from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { Heading, ISingleChoiceButtonOption, SingleChoice } from '@ruby-labs/ui-core-kit';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { ExperimentsKeys } from 'constants/experiments';

export const WORKOUT_PLACES_ANSWERS: ISingleChoiceButtonOption[] = [
    {
        value: 'gym',
        label: i18n.t('workout-place-option-gym'),
    },
    {
        value: 'home',
        label: i18n.t('workout-place-option-home'),
    },
    {
        value: 'outdoor',
        label: i18n.t('workout-place-option-outdoor'),
    }
];

export const WorkoutPlace = () => {
    const { t } = useTranslation();
    const { pageConfiguration } = useContext(BaseContext);

    const saveAnswers = useSaveAnswers();

    const mg_personal_statement_screens = useFeatureIsOn(ExperimentsKeys.mg_personal_statement_screens as any);

    useEffect(() => {
        localStorage.setItem(ExperimentsKeys.mg_personal_statement_screens, mg_personal_statement_screens ? '1' : '0');
    }, [mg_personal_statement_screens]);

    const pageKey = LocalStorageKeys[RoutePath.WorkoutPlace];

    const { pageValue, setPageValue }: any = useLocalStorage({
        key: pageKey,
        defaultValue: '',
    });

    const handleClick = (option: ISingleChoiceButtonOption) => {
        saveAnswers({
            key: pageKey,
            itemQuestion: { question: t('workout-place-title'), value: option.label },
        });

        setPageValue(option);

        pageConfiguration.handleOnPageComplete(option.value);
    };

    return (
        <>
            <Heading title={t('workout-place-title')} />

            <SingleChoice
                options={WORKOUT_PLACES_ANSWERS}
                selectedOption={pageValue}
                onSelect={handleClick} />
        </>
    );
};
