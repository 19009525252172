import {ISingleChoiceButtonOption} from '@ruby-labs/ui-core-kit';

type TitleData = {
    value: string;
    range: [number, number];
    time: number;
}

type PopupData = {
    title: string;
    event: string;
    storageKey: string;
    answers: ISingleChoiceButtonOption[];
}

type PlanLoaderStep = {
    titles: TitleData[];
    popUpTime?: number;
    popUpData?: PopupData;
}

export type PlanLoaderDataType = PlanLoaderStep[];

export const planLoaderData: PlanLoaderDataType = [
    {
        'titles': [
            {
                'value': 'plan-loader-analyzing-profile-title',
                'range': [0, 20],
                'time': 500,
            },
            {
                'value': 'plan-loader-comparing-demographic-data-title',
                'range': [21, 63],
                'time': 5000,
            },
            {
                'value': 'plan-loader-evaluating-body-composition-title',
                'range': [64, 100],
                'time': 1500,
            },
        ],
        'popUpTime': 64,
        'popUpData': {
            'title': 'plan-loader-popup-family-disease-title',
            'storageKey': 'heart-disease-diabetes-history',
            'event': 'OnboardingPopupQuestionFamilyDiseaseClicked',
            'answers': [
                {
                    'value': 'yes',
                    'label': 'plan-loader-popup-family-disease-yes-answer',
                },
                {
                    'value': 'no',
                    'label': 'plan-loader-popup-family-disease-no-answer',
                },
            ],
        },
    },
    {
        'titles': [
            {
                'value': 'plan-loader-analyzing-activity-level-title',
                'range': [0, 40],
                'time': 1000,
            },
            {
                'value': 'plan-loader-building-habit-profile-title',
                'range': [41, 70],
                'time': 5000,
            },
            {
                'value': 'plan-loader-charting-best-fit-plan-title',
                'range': [71, 100],
                'time': 2000,
            },
        ],
        'popUpTime': 40,
        'popUpData': {
            'title': 'plan-loader-popup-work-activity-title',
            'event': 'OnboardingPopupQuestionWorkActivityClicked',
            'storageKey': 'work-activity',
            'answers': [
                {
                    'value': 'active',
                    'label': 'plan-loader-popup-family-disease-active-answer',
                },
                {
                    'value': 'seated',
                    'label': 'plan-loader-popup-family-disease-seated-answer',
                },
            ],
        },
    },
    {
        'titles': [
            {
                'value': 'plan-loader-evaluating-diet-preferences-title',
                'range': [0, 40],
                'time': 4000,
            },
            {
                'value': 'plan-loader-assessing-underlying-psychology-title',
                'range': [41, 80],
                'time': 2000,
            },
            {
                'value': 'plan-loader-predicting-future-results-title',
                'range': [81, 100],
                'time': 3000,
            },
        ],
        'popUpTime': 80,
        'popUpData': {
            'title': 'plan-loader-popup-meals-time-title',
            'event': 'OnboardingPopupQuestionMealsClicked',
            'storageKey': 'same-time-a-day-meals',
            'answers': [
                {
                    'value': 'yes',
                    'label': 'plan-loader-popup-meals-time-yes-answer',
                },
                {
                    'value': 'no',
                    'label': 'plan-loader-popup-meals-time-no-answer',
                },
            ],
        },
    }
];
