import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {Heading, IMultiChoiceButtonOption, MultiChoice} from '@ruby-labs/ui-core-kit';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';

import { NextButton } from 'components/next-button';
import { FloatedButton } from 'components/floated-button';
import {getMultiChoiceSelected} from '../../helpers/get-multichoice-button-select-options';

export const ConsumedItems = () => {
    const { t } = useTranslation();
    const saveAnswers = useSaveAnswers();

    const { pageConfiguration } = useContext(BaseContext);

    const pageKey = LocalStorageKeys[RoutePath.ConsumedItems];

    const { pageValue, setPageValue } = useLocalStorage({
        key: pageKey,
        defaultValue: [],
    });

    const options: IMultiChoiceButtonOption[] = [
        {
            value: 'sweet',
            label: t('consumed-items-option-sweet')
        },
        {
            value: 'alcohol',
            label: t('consumed-items-option-alcohol')
        },
        {
            value: 'salty',
            label: t('consumed-items-option-salty')
        },
        {
            value: 'fried',
            label: t('consumed-items-option-fried')
        },
        {
            value: 'sugary',
            label: t('consumed-items-option-sugary')
        },
        {
            value: 'none',
            label: t('consumed-items-option-none')
        },
    ];

    const handleClick = () => {
        const { values, labels } = getMultiChoiceSelected(pageValue);

        saveAnswers({
            key: pageKey,
            itemQuestion: { question: t('consumed-items-title'), value: labels },
        });

        pageConfiguration.handleOnPageComplete({ value: values });
    };

    return (
        <>
            <Heading title={t('consumed-items-title')} subtitle={t('consumed-items-subtitle')} />

            <MultiChoice
                options={options}
                deselectWhenValues={['none']}
                selectedOptions={pageValue}
                onSelect={setPageValue} />

            <FloatedButton floated={pageValue.length} withGradient>
                <NextButton onClick={handleClick} disabled={!pageValue.length} />
            </FloatedButton>
        </>
    );
};
