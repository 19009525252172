import React, { Dispatch, FC, SetStateAction } from 'react';
import { Trans } from 'react-i18next';

import { RoutePath } from 'routes/route-path.constant';

import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { UNIT_SYSTEM } from 'constants/body-height-weight';
import { LocalStorageKeys, MultipleLocalStorageKeys } from 'constants/local-storage';
import { UserGeolocationKeys } from 'constants/user-geolocation';
import { TypographyVariants } from 'constants/typography-variants';

import { Typography } from 'components/typography';
import { LbsInput } from 'components/mass-input/lbs-input';
import { KgInput } from 'components/mass-input/kg-input';

import {
    convertToCm,
    convertToFtInches,
    convertToKg,
    convertToLbs,
} from 'helpers/unit-converter';

import { CmInput } from './cm-input';
import { FtInchesInputs } from './ft-inches-inputs';

import { useStyles } from './styles';

type ErrorType = {
    error: boolean;
    key: string;
}

export interface BodyHeightWeightInputsProps {
    heightInput: boolean;
    idealWeightInput: boolean;
    setInputError: Dispatch<SetStateAction<ErrorType>>;
    pageError: boolean;
}

export const BodyHeightWeightInputs: FC<BodyHeightWeightInputsProps> = ({
                                                                            heightInput,
                                                                            idealWeightInput,
                                                                            setInputError,
                                                                            pageError,
                                                                        }) => {
    const { switcherButton, switcherLink, excitementTextWrapper, excitementText } = useStyles();

    const bodyHeightWeightKeys = MultipleLocalStorageKeys.bodyHeightWeightKeys;
    const countryCode = localStorage.getItem(UserGeolocationKeys.countryCode) as string;

    const { pageValue: bodyHeightWeightUnitSystem, setPageValue: setBodyHeightWeightUnitSystem } =
        useLocalStorage({
            key: bodyHeightWeightKeys.unitSystem,
            defaultValue: countryCode === 'US' ? UNIT_SYSTEM.imperial : UNIT_SYSTEM.metric,
        });

    const { pageValue: bodyHeightCm, setPageValue: setBodyHeightCm } = useLocalStorage({
        key: bodyHeightWeightKeys.heightCm,
        defaultValue: '',
    });

    const { pageValue: bodyHeightFt, setPageValue: setBodyHeightFt } = useLocalStorage({
        key: bodyHeightWeightKeys.heightFt,
        defaultValue: '',
    });

    const { pageValue: bodyHeightInches, setPageValue: setBodyHeightInches } = useLocalStorage({
        key: bodyHeightWeightKeys.heightInches,
        defaultValue: '',
    });

    const { pageValue: bodyWeightLbs, setPageValue: setBodyWeightLbs } = useLocalStorage({
        key: bodyHeightWeightKeys.weightLbs,
        defaultValue: '',
    });

    const { pageValue: bodyWeightKg, setPageValue: setBodyWeightKg } = useLocalStorage({
        key: bodyHeightWeightKeys.weightKg,
        defaultValue: '',
    });

    const { pageValue: bodyIdealWeight, setPageValue: setBodyIdealWeight } = useLocalStorage({
        key: LocalStorageKeys[RoutePath.BodyIdealWeight],
        defaultValue: '',
    });

    const minKgValue = 26.76;
    const maxKgValue = 454.046;
    const minLbsValue = 59;
    const maxLbsValue = 1002;
    const minFtValue = 1;
    const maxFtValue = 9;
    const maxInchesValue = 12;
    const minCmValue = 60;
    const maxCmValue = 273;

    const onTabChange = (nextTab: string) => {
        if (nextTab === UNIT_SYSTEM.imperial) {
            const lbs = convertToLbs(Number(bodyWeightKg));
            const idealLbs = convertToLbs(Number(bodyIdealWeight));
            const { ft: convertedFt, inches: convertedInches } = convertToFtInches(
                Number(bodyHeightCm),
            );

            if (lbs && lbs > minLbsValue && lbs < maxLbsValue) {
                setBodyWeightLbs(lbs);
            } else if (bodyWeightKg === '') {
                setBodyWeightLbs('');
            }

            if (idealLbs) {
                setBodyIdealWeight(idealLbs);
            } else if (bodyIdealWeight === '') {
                setBodyIdealWeight('');
            }

            if (convertedFt && convertedFt > minFtValue && convertedFt < maxFtValue) {
                setBodyHeightFt(convertedFt);
            } else if (bodyHeightCm === '') {
                setBodyHeightFt('');
            }

            if (convertedFt && convertedFt > minFtValue && convertedFt < maxFtValue && convertedInches && convertedInches < maxInchesValue) {
                setBodyHeightInches(convertedInches);
            } else if (bodyHeightCm === '') {
                setBodyHeightInches('');
            }
        } else {
            const kg = convertToKg(Number(bodyWeightLbs));
            const idealKg = convertToKg(Number(bodyIdealWeight));
            const cm = convertToCm(Number(bodyHeightFt), Number(bodyHeightInches));

            if (kg && kg > minKgValue && kg < maxKgValue) {
                setBodyWeightKg(kg);
            } else if (bodyWeightLbs === '') {
                setBodyWeightKg('');
            }

            if (idealKg) {
                setBodyIdealWeight(idealKg);
            } else if (bodyIdealWeight === '') {
                setBodyIdealWeight('');
            }

            if (cm && cm > minCmValue && cm < maxCmValue) {
                setBodyHeightCm(cm);
            } else if (bodyHeightFt === '' && bodyHeightInches === '') {
                setBodyHeightCm('');
            }
        }
        setBodyHeightWeightUnitSystem(nextTab);
    };

    const onChangeFt = (e: any) => {
        setBodyHeightFt(e.target.value);
        const { name } = e.target;
        const [, fieldIndex] = name.split('-');

        let fieldIntIndex = parseInt(fieldIndex, 10);
        if (e.target.value.length === 1 && e.target.value > 1 && e.target.value < 9) {
            const nextField = document.querySelector(
                `input[name=field-${fieldIntIndex + 1}]`,
            ) as HTMLInputElement;
            nextField.focus();
        }
    };

    const onChangeInches = (e: any) => {
        setBodyHeightInches(e.target.value);
    };

    const onChangeCm = (e: any) => {
        setBodyHeightCm(e.target.value);
    };

    const onChangeLbs = (e: any) => {
        idealWeightInput ? setBodyIdealWeight(e.target.value) : setBodyWeightLbs(e.target.value);
    };

    const onChangeKg = (e: any) => {
        idealWeightInput ? setBodyIdealWeight(e.target.value) : setBodyWeightKg(e.target.value);
    };

    const onError = (error: boolean, key: string) => {
        setInputError((prevState: ErrorType) => ({
            ...prevState,
            [key]: error,
        }));
    };

    const currentWeight = bodyHeightWeightUnitSystem === UNIT_SYSTEM.imperial ? parseInt(bodyWeightLbs) : parseInt(bodyWeightKg);
    const idealWeight = parseInt(bodyIdealWeight);

    const idealWeightDifferenceInPercentage = Math.round(Math.abs((idealWeight - currentWeight) * 100 / currentWeight));
    const exceptedWeightDifferenceRange = idealWeightDifferenceInPercentage >= 5 && idealWeightDifferenceInPercentage <= 15;

    const getExcitementTextKeys = () => {
        let titleKey;
        let descriptionKey;

        if (idealWeight < currentWeight && exceptedWeightDifferenceRange) {
            titleKey = 'body-ideal-weight-goal-excitement-title-great';
            descriptionKey = 'body-ideal-weight-goal-excitement-description-great';
        } else if (idealWeightDifferenceInPercentage < 5) {
            titleKey = 'body-ideal-weight-goal-excitement-title-awesome';
            descriptionKey = 'body-ideal-weight-goal-excitement-description-awesome';
        } else if (idealWeight > currentWeight && exceptedWeightDifferenceRange) {
            titleKey = 'body-ideal-weight-goal-excitement-title-fantastic';
            descriptionKey = 'body-ideal-weight-goal-excitement-description-fantastic';
        } else {
            titleKey = 'body-ideal-weight-goal-excitement-title-healthier-goal';
            descriptionKey = 'body-ideal-weight-goal-excitement-description-healthier-goal';
        }

        return { titleKey, descriptionKey };
    };

    const setCurrentTab = () => {
        const newTab = bodyHeightWeightUnitSystem === UNIT_SYSTEM.imperial ? UNIT_SYSTEM.metric : UNIT_SYSTEM.imperial;
        onTabChange(newTab);
    };

    const heightInputSwitcherButtonText = bodyHeightWeightUnitSystem === UNIT_SYSTEM.imperial ? 'Switch to cm' : 'Switch to ft';
    const weightInputSwitcherButtonText = bodyHeightWeightUnitSystem === UNIT_SYSTEM.imperial ? 'Switch to kg' : 'Switch to lbs';

    return (
        <>
            {bodyHeightWeightUnitSystem === UNIT_SYSTEM.imperial && (
                <>
                    {heightInput && <FtInchesInputs
                        ft={bodyHeightFt}
                        inches={bodyHeightInches}
                        onChangeFt={onChangeFt}
                        onChangeInches={onChangeInches}
                        onError={onError}
                    />}

                    {!heightInput && !idealWeightInput && <div>
                        <LbsInput value={bodyWeightLbs} onChange={onChangeLbs} onError={onError} />
                    </div>}

                    {!heightInput && idealWeightInput && <div>
                        <LbsInput value={bodyIdealWeight} onChange={onChangeLbs}
                                  onError={onError} />
                    </div>}
                </>
            )}

            {bodyHeightWeightUnitSystem === UNIT_SYSTEM.metric && (
                <>
                    {heightInput && <CmInput value={bodyHeightCm} onChange={onChangeCm} onError={onError} />}

                    {!heightInput && !idealWeightInput && <div>
                        <KgInput value={bodyWeightKg} onChange={onChangeKg} onError={onError} />
                    </div>}

                    {!heightInput && idealWeightInput && <div>
                        <KgInput value={bodyIdealWeight} onChange={onChangeKg}
                                 onError={onError} />
                    </div>}
                </>
            )}

            {!pageError && bodyIdealWeight && idealWeightInput && (
                <div className={excitementTextWrapper}>
                    <Typography className={excitementText} variant={TypographyVariants.h3}>
                        <Trans i18nKey={getExcitementTextKeys().titleKey} />
                    </Typography>
                    <Typography className={excitementText} variant={TypographyVariants.h3}>
                        <Trans i18nKey={getExcitementTextKeys().descriptionKey} />
                    </Typography>
                </div>
            )}

            {!idealWeightInput && <button className={switcherButton} onClick={() => setCurrentTab()}>
                <h2 className={switcherLink}>
                    {heightInput ? heightInputSwitcherButtonText : weightInputSwitcherButtonText}
                </h2>
            </button>}
        </>
    );
};
