import React from 'react';
import { useTranslation } from 'react-i18next';
import { ISingleChoiceButtonOption } from '@ruby-labs/ui-core-kit';

import { RoutePath } from 'routes/route-path.constant';
import { LocalStorageKeys } from 'constants/local-storage';

import { TipPage } from 'components/tip-page';

export const SpecificDietTip = () => {
    const { t } = useTranslation();

    const userDiet = JSON.parse(localStorage.getItem(LocalStorageKeys[RoutePath.SpecificDiet]) as any) as ISingleChoiceButtonOption;

    return (
        <TipPage
            imgSrc='svg/reinforcement-icons/apple.svg'
            text={{
                description: 'specific-diet-tip-description',
                values: userDiet.value === 'other' ? t('specific-diet-tip-option-specific-diet') : userDiet.label
            }}
        />
    );
};
