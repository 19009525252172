import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    description: {
        padding: '16px',
        border: '2px solid #3A3A3A',
        borderRadius: '12px',
        '& p': {
            color: '#D3D3D3',
            lineHeight: '18px',
            fontSize: '14px',
            textAlign: 'inherit'
        }
    }
});
