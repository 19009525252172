import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, ISingleChoiceButtonOption, SingleChoice } from '@ruby-labs/ui-core-kit';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useSaveAnswers } from 'hooks/use-save-answers.hook';
import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';

export enum SleepHoursAnswers { LESS5 = '5-less-hours', FROM5TO6 = '5-6-hours', FROM7TO8 = '7-8-hours', MORE8 = '8-more-hours' }

export const SleepHours = () => {
    const { t } = useTranslation();
    const { pageConfiguration } = useContext(BaseContext);

    const saveAnswers = useSaveAnswers();

    const pageKey = LocalStorageKeys[RoutePath.SleepHours];

    const { pageValue, setPageValue }: any = useLocalStorage({
        key: pageKey,
        defaultValue: '',
    });

    const handleClick = (option: ISingleChoiceButtonOption) => {
        saveAnswers({
            key: pageKey,
            itemQuestion: { question: t('sleep-hours-title'), value: option.label },
        });
        setPageValue(option);

        pageConfiguration.handleOnPageComplete(option.value);
    };

    const options: ISingleChoiceButtonOption[] = [
        {
            value: SleepHoursAnswers.LESS5,
            label: t('sleep-hours-option-5-less-hours'),
        },
        {
            value: SleepHoursAnswers.FROM5TO6,
            label: t('sleep-hours-option-5-6-hours'),
        },
        {
            value: SleepHoursAnswers.FROM7TO8,
            label: t('sleep-hours-option-7-8-hours'),
        },
        {
            value: SleepHoursAnswers.MORE8,
            label: t('sleep-hours-option-8-more-hours'),
        },
    ];

    return (
        <>
            <Heading title={t('sleep-hours-title')} />

            <SingleChoice
                options={options}
                selectedOption={pageValue}
                onSelect={handleClick} />
        </>
    );
};
