import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Heading } from '@ruby-labs/ui-core-kit';

import { pageConfiguration } from 'containers/index';

import { RoutePath } from 'routes/route-path.constant';

import { NextButton } from 'components/next-button';

import { useStyles as ContentUseStyles } from 'containers/content/styles';

export const NotFound = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { mainContent } = ContentUseStyles({ scrollableContent: false });
    const handleClick = () => {
        pageConfiguration.event('MenNotFoundPageClicked');
        navigate(`..${RoutePath.StartQuiz}`);
    };

    return (
        <div className={mainContent}>
            <Heading title={t('not-found-title')} subtitle={t('not-found-description')} />

            <NextButton onClick={handleClick} typographyText={t('not-found-next-button')} />
        </div>
    );
};
