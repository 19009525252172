import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useImagePreloadingService } from '@ruby-labs/ui-core-kit';

import dayjs, { ManipulateType } from 'dayjs';

import { getPrice } from 'helpers/payment';

import { CurrencyLabelsByCodes } from 'constants/currency-labels';
import { PLANCODE_PRIMERIO_TYPE } from 'constants/trial-pay';

import { SkipTrialModalWindow } from 'pages/upgrade-plan/skip-trial-modal-window';
import { LoadingModalWindow } from 'pages/paywall/payment/loading-modal-window';

import { NextButton } from 'components/next-button';

import { PLANS_FOR_UPGRADE_TYPE } from '../index';

import { useStyles } from './styles';

function getCurrencyLabelsByCode(code: string) {
    return CurrencyLabelsByCodes[code] || CurrencyLabelsByCodes.USD;
}

interface ContentProps {
    loading: boolean;
    showSkipTrialModalWindow: boolean;
    handleSkipTrialModalWindow: () => void;
    handleUpgradeTrialModalWindow: () => void;
    handleSkipUpgradeSubscription: () => void;
    handleUpgradeSubscription: () => void;
    plan: PLANCODE_PRIMERIO_TYPE;
    newPlan: PLANS_FOR_UPGRADE_TYPE;
}

export const Content: FC<ContentProps> = ({
    loading,
    showSkipTrialModalWindow,
    handleSkipTrialModalWindow,
    handleUpgradeTrialModalWindow,
    handleSkipUpgradeSubscription,
    handleUpgradeSubscription,
    plan,
    newPlan,

}) => {
    const { t } = useTranslation();

    const {
        pageWrapper,
        title,
        description,
        image,
        offerWrapper,
        calculationBlock,
        savingPercentWrapper,
        savingPercent,
        blue,
        totalWrapper,
        infoBlock,
        upgradePlanButton,
        continueWithTrialButton
    } = useStyles();

    const { getImageByUrl } = useImagePreloadingService();

    const currencyLabel = getCurrencyLabelsByCode(plan.currencyCode);

    const numberOfWeeks = dayjs().add(newPlan.chargePeriod, newPlan.chargeSubscriptionPeriodUnit as ManipulateType).diff(dayjs(), 'week');

    const originalWeekAmount = getPrice(plan.subscriptionAmount);

    const totalAmount = getPrice(newPlan.amount);

    const discountedWeekAmount = totalAmount / numberOfWeeks;

    const totalSaving = (originalWeekAmount * numberOfWeeks) - totalAmount;

    const savingPerWeek = +(originalWeekAmount - discountedWeekAmount).toFixed(2);

    const savingPerWeekInPercent = Math.round((savingPerWeek * 100) / originalWeekAmount);

    const planData = newPlan.key.split('-');

    const upgradePlanPeriod = +planData[planData.length - 2];
    const upgradePlanPeriodUnit = planData[planData.length - 1];

    let paidPeriod;
    let paidPeriodUnit;
    let freePeriod;
    let freePeriodUnit;

    if (upgradePlanPeriod === 1) {
        paidPeriod = 3;
        paidPeriodUnit = 'weeks';
        freePeriod = 1;
        freePeriodUnit = 'week';
    } else {
        if (upgradePlanPeriod === 3) {
            paidPeriod = 2;
            freePeriod = 1;
            freePeriodUnit = 'month';
        } else if (upgradePlanPeriod === 6) {
            paidPeriod = 4;
            freePeriod = 2;
            freePeriodUnit = 'months';
        } else {
            paidPeriod = 6;
            freePeriod = 6;
            freePeriodUnit = 'months';
        }
    }

    return (

        <div className={pageWrapper}>

            <LoadingModalWindow loading={loading} />

            <SkipTrialModalWindow showSkipTrialModalWindow={showSkipTrialModalWindow}
                                  upgradePlanMonthsCount={upgradePlanPeriod}
                                  handleSkipClick={handleSkipTrialModalWindow}
                                  handleUpgradeClick={handleUpgradeTrialModalWindow}
            />

            <h1 className={title}>
                <Trans i18nKey='upgrade-plan-title' />
            </h1>

            <h2 className={description}>
                <Trans i18nKey='upgrade-plan-description' components={[<span></span>]} />
            </h2>

            <img className={image} src={getImageByUrl('svg/upgrade-plan/content/commitment-graph.svg')} alt='Commitment Graph' />

            <div className={offerWrapper}>
                <h1>
                    <Trans i18nKey='upgrade-plan-commitment-offer-title' />
                </h1>
                <h2>
                    {t('upgrade-plan-commitment-offer-subtitle', { upgradePlanPeriod,  upgradePlanPeriodUnit, paidPeriod, paidPeriodUnit, freePeriod, freePeriodUnit })}
                </h2>

                <div className={calculationBlock}>
                    <span>
                        <Trans i18nKey='upgrade-plan-week-plan-with-trial' />
                    </span>
                    <span>
                        {currencyLabel}{originalWeekAmount}
                    </span>
                </div>

                <div className={calculationBlock}>
                    <div className={savingPercentWrapper}>
                        <div className={savingPercent}>
                            <span>
                                <Trans i18nKey='upgrade-plan-saving-per-week-in-percent' values={{savingPerWeekInPercent}} />
                            </span>
                        </div>
                        <span className={blue}>
                            <Trans i18nKey='upgrade-plan-skip-trial' />
                        </span>
                    </div>
                    <span className={blue}>
                        <Trans i18nKey='upgrade-plan-saving-per-week' values={{currencyLabel, savingPerWeek}} />
                    </span>
                </div>

                <div className={calculationBlock}>
                    <span>
                        <Trans i18nKey='upgrade-plan-total-savings' />
                    </span>
                    <span>
                        {currencyLabel}{totalSaving}
                    </span>
                </div>

                <div className={calculationBlock}>
                    <span>
                        <Trans i18nKey='upgrade-plan-total' />
                    </span>
                    <div className={totalWrapper}>
                        <span>
                            <Trans i18nKey='upgrade-plan-total-price-per-week' values={{ currencyLabel, discountedWeekAmount: discountedWeekAmount.toFixed(2) }} />
                        </span>
                        <span>
                            {currencyLabel}{originalWeekAmount}
                        </span>
                    </div>
                </div>

                <div className={calculationBlock}>
                    <span>
                        <Trans i18nKey='upgrade-plan-billed-every' values={{upgradePlanMonths: upgradePlanPeriod, count: upgradePlanPeriod }} />
                    </span>
                    <span>
                        {currencyLabel}{totalAmount}
                    </span>
                </div>
            </div>

            <div className={infoBlock}>
                <ul>
                    <li>
                        <Trans i18nKey='upgrade-plan-subscription-info' />
                    </li>
                    <li>
                        <Trans i18nKey='upgrade-plan-charge-info' values={{ savingPerWeek, currencyLabel, totalAmount, upgradePlanMonths: upgradePlanPeriod, count: upgradePlanPeriod }} />
                    </li>
                    <li>
                        <Trans i18nKey='upgrade-plan-cancel-info' />
                    </li>
                    <li>
                        <Trans i18nKey='upgrade-plan-refund-info' />
                    </li>
                    {/*<li>*/}
                    {/*  <Trans i18nKey='upgrade-plan-charge-currency-info' />*/}
                    {/*</li>*/}
                </ul>
            </div>

            <NextButton className={upgradePlanButton} typographyText={t('upgrade-plan-skip-trial-button', { currencyLabel, savingPerWeek })} onClick={handleUpgradeSubscription} />
            <NextButton className={continueWithTrialButton} typographyText={t('upgrade-plan-continue-with-trial-button')} onClick={handleSkipUpgradeSubscription} />
        </div>
    );
};
