import React, { FC } from 'react';

import { useStyles } from './styles';

interface TipInfoBlockProps {
    title?: string;
    info: string;
}

export const TipInfoBlock: FC<TipInfoBlockProps> = ({ title, info }): JSX.Element => {
    const { description  } = useStyles();

    return (
        <div className={description}>
            {title && <h3>{title}</h3>}
            <p>{info}</p>
        </div>
    );
};
