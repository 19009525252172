import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useImagePreloadingService } from '@ruby-labs/ui-core-kit';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';
import { BODY_FAT_PERCENTAGE } from 'constants/body-fat';

import { getUserBmi, getUserFFMI } from 'services/body-plan-calculator.service';

import { NextButton } from 'components/next-button';
import { MuscleLevel } from 'components/muscle-level';
import { TipInfoBlock } from 'components/tip-info-block';

import { useStyles } from './styles';

const getBMILevel = (userBMI: number): number => {
    if (userBMI < 18.5) {
        return 0;
    }

    if (userBMI >= 18.5 && userBMI <= 24.9) {
        return 1;
    }

    if (userBMI > 24.9 && userBMI <= 29.9) {
        return 2;
    }

    return 3;
};

export const BodyHealthSummary = () => {
    const { t } = useTranslation();

    const { wrapper, title, summaryBlock, summaryInfoWrapper, summaryInfoBlock, fatInfo, bodyImageWrapper,
        bmiBlock, bmiBlockInfo,  slider, popoverWrapper, popover, dotSymbol, sliderFooter } = useStyles();

    const { pageConfiguration } = useContext(BaseContext);

    const { pageValue: bodyFat }: any = useLocalStorage({
        key: LocalStorageKeys[RoutePath.BodyFat],
        defaultValue: 1,
    });

    const images = [
        'webp/body-fat/5-9.webp', 'webp/body-fat/10-14.webp',
        'webp/body-fat/15-19.webp', 'webp/body-fat/20-24.webp',
        'webp/body-fat/25-29.webp', 'webp/body-fat/30-34.webp',
        'webp/body-fat/35-39.webp', 'webp/body-fat/40.webp',
    ];

    const { getImageByUrl } = useImagePreloadingService();

    const userBMI = Number(getUserBmi().toFixed(1));
    console.log('userBMI', userBMI);
    const BMILevel = getBMILevel(userBMI);
    const userFFMI = getUserFFMI();
    const userBodyFat = BODY_FAT_PERCENTAGE[bodyFat - 1];

    const bmiTitles = [
        t('body-health-summary-bmi-level-underweight'),
        t('body-health-summary-bmi-level-normal'),
        t('body-health-summary-bmi-level-overweight'),
        t('body-health-summary-bmi-level-obese')
    ];

    const sliderPopupLeft = ['3%', '35%', '64%', '91%'];

    const handleClick = () => {
        pageConfiguration.handleOnPageComplete({ bmi: userBMI, ffmi: userFFMI });
    };

    const tipDescription = useMemo(() => {
        const maxBodyFatPercentage = 14;
        const bodyFatHeight = ((userBodyFat.min + userBodyFat.max) / 2) <= maxBodyFatPercentage ? 'low' : 'high';

        if (userBMI < 18.5) {
            return 'body-health-summary-tip-description-main-rule';
        }

        if (userFFMI < 20) {
            return `body-health-summary-tip-description-low-ffmi-${bodyFatHeight}-fat`;
        }

        if (userFFMI >= 20 && userFFMI < 25) {
            return `body-health-summary-tip-description-mid-ffmi-${bodyFatHeight}-fat`;
        }

        if (userFFMI >= 25 && userFFMI < 30) {
            return `body-health-summary-tip-description-high-ffmi-${bodyFatHeight}-fat`;
        }

        // userFFMI >= 30
        return `body-health-summary-tip-description-max-ffmi-${bodyFatHeight}-fat`;
    }, [userBMI, userBodyFat]);

    return (
        <div className={wrapper}>

            <h1 className={title}>{t('body-health-summary-title')}</h1>

            <div className={summaryBlock}>

                <div className={summaryInfoWrapper}>

                    <div className={summaryInfoBlock}>
                        <p>{t('body-health-summary-bmi-label')}</p>
                        <p className={fatInfo}>
                            {userBodyFat.label}
                        </p>
                    </div>

                    <div className={summaryInfoBlock}>
                        <MuscleLevel label={t('body-health-summary-muscle-label')} ffmi={userFFMI} />
                    </div>

                </div>

                <div className={bodyImageWrapper}>
                    <img src={getImageByUrl(images[bodyFat - 1])} alt='Your body' />
                </div>

            </div>

            <div className={bmiBlock}>
                <div className={bmiBlockInfo}>
                    <span>
                        {t('body-health-summary-bmi-slider-label')}
                    </span>
                    <span>
                        {`${userBMI} (${bmiTitles[BMILevel]})`}
                    </span>
                </div>
                <div>
                    <div className={popoverWrapper} style={{left: sliderPopupLeft[BMILevel]}}>
                        <div className={popover}>
                            <span>
                                {t('body-health-summary-bmi-slider-popover')}
                            </span>
                        </div>
                        <div>
                            <span className={dotSymbol}></span>
                        </div>
                    </div>
                    <div className={slider}></div>
                </div>

                <div className={sliderFooter}>
                    <span>{bmiTitles[0]}</span>
                    <span>{bmiTitles[bmiTitles.length - 1]}</span>
                </div>

            </div>

            <TipInfoBlock info={t(tipDescription)}/>

            <NextButton onClick={handleClick} typographyText={t('body-health-summary-next-button')}/>
        </div>
    );
};
