import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, ISingleChoiceButtonOption, SingleChoice } from '@ruby-labs/ui-core-kit';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';

import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';

export const NewsOffer = () => {
    const { t } = useTranslation();

    const { pageValue, setPageValue }: any = useLocalStorage({
        key: LocalStorageKeys[RoutePath.NewsOffer],
        defaultValue: '',
    });

    const { pageConfiguration } = useContext(BaseContext);

    const handleClick = (answer: ISingleChoiceButtonOption) => {
        setPageValue(answer);

        pageConfiguration.handleOnPageComplete(answer.value);
    };

    const options = [
        { value: 'yes', label: t('news-offer-option-yes') },
        { value: 'no', label: t('news-offer-option-no') },
    ];

    return (
        <>
            <Heading title={t('news-offer-title')} />

            <SingleChoice
                options={options}
                selectedOption={pageValue}
                onSelect={handleClick} />
        </>
    );
};
