import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';
import { RoutePath } from 'routes/route-path.constant';
import { PLANCODE_PRIMERIO } from 'constants/trial-pay';

import { useLocalStorage } from 'hooks/use-local-storage.hook';

import { LocalStorageKeys } from 'constants/local-storage';

import { RatingButton } from 'components/rating-button';
import { NextButton } from 'components/next-button';

import pointingArrow from 'assets/images/svg/trial-pay/pointing-arrow.svg';

import { disableBrowserBackButton } from 'helpers/disable-browser-back-button';

import { useStyles } from './styles';

export const TrialPay = () => {
    const { pageConfiguration } = useContext(BaseContext);

    disableBrowserBackButton(window.location.href);

    const { t } = useTranslation();

    const {
        pageWrapper,
        title,
        paragraph,
        lastParagraph,
        ratingScaleWrapper,
        ratingScale,
        ratingButton,
        ratingButtonText,
        supportOptionDescriptionWrapper,
        smallerBottomIndent,
        supportOption,
        arrowImage,
        bestSupportOption,
        nextButton
    } = useStyles();

    const { pageValue, setPageValue }: any = useLocalStorage({
        key: LocalStorageKeys[RoutePath.TrialPay],
        defaultValue: {},
    });

    const handleClick = () => {
        pageConfiguration.handleOnPageComplete({ currency: pageValue.currencyCode, trial_price: pageValue?.trialAmount / 100 });
    };

    const isBestTrialOptionChosen = (PLANCODE_PRIMERIO && pageValue.trialAmount === PLANCODE_PRIMERIO[PLANCODE_PRIMERIO.length - 1]?.trialAmount) || false;

    return (
        <div className={pageWrapper}>
            <h1 className={title}>
                {t('trial-pay-title')}
            </h1>

            <h2 className={paragraph}>
                {t('trial-pay-helped-thousands-paragraph')}
            </h2>

            <h2 className={paragraph}>
                {t('trial-pay-money-reason-paragraph')}
            </h2>

            <h2 className={`${paragraph} ${lastParagraph}`}>
                <Trans i18nKey='trial-pay-able-costs-paragraph' />
            </h2>

            <div className={ratingScaleWrapper}>
                <h1 className={title}>
                    {t('trial-pay-subtitle')}
                </h1>

                <div className={ratingScale}>
                    {
                        PLANCODE_PRIMERIO?.map((plan: any) =>
                            <div key={plan.trialAmount}>
                                <RatingButton
                                    rating={{...plan, value: `${plan.currencyLabel}${plan.trialAmount / 100}`}}
                                    selected={plan.trialAmount === pageValue?.trialAmount}
                                    buttonClass={ratingButton}
                                    buttonTextClass={ratingButtonText}
                                    handleClick={() => setPageValue(plan)}
                                />
                            </div>
                        )
                    }
                </div>
                <div className={`${supportOptionDescriptionWrapper} ${isBestTrialOptionChosen && smallerBottomIndent}`}>
                    <p className={supportOption}>
                        {t('trial-pay-support-option-text')}
                    </p>
                    <img src={pointingArrow} className={arrowImage} alt='Arrow' />
                </div>
                {isBestTrialOptionChosen && <p className={bestSupportOption}>
                    {t('trial-pay-best-support-option-text')}
                </p>}
            </div>
            <NextButton className={nextButton} onClick={() => handleClick()} disabled={!pageValue.key} />
        </div>
    );
};
