import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

import {
    SwitchingTitle,
    LOGO_STYLE,
    ReviewsIOWidget,
    LinearProgressWithTitleAndLabel
} from '@ruby-labs/ui-core-kit';

import { pageConfiguration } from 'containers/index';

import { ModalContent, PopupData } from 'pages/plan-loader/modal-content';
import { planLoaderData } from 'pages/plan-loader/plan-loader-data';

import { ModalWindow } from 'components/modal-window';

import { ExperimentsKeys } from 'constants/experiments';
import { LOADER_COLORS, MAX_PERCENTS } from 'constants/plan-loader';
import { ABLE_STORE } from 'constants/able-store';

import logo from 'assets/images/svg/logo.svg';

import { useStyles } from './styles';

export const PlanLoader = () => {
    const { t } = useTranslation();

    const { pageWrapper, pageContent, title, loaderWrapper, modalWindow } = useStyles();

    const [activeBar, setActiveBar] = useState(0);
    const [percents, setPercents] = useState(planLoaderData.map(() => 0));
    const [step, setStep] = useState<number>(0);
    const [activeProgress, toggleActiveProgress] = useState(true);

    const mg_finisher_screens = useFeatureIsOn(ExperimentsKeys.mg_finisher_screens as any);

    useEffect(() => {
        localStorage.setItem(ExperimentsKeys.mg_finisher_screens, mg_finisher_screens ? '1' : '0');
    }, [mg_finisher_screens]);

    useEffect(() => {
        let interval: ReturnType<typeof setInterval> | undefined = undefined;

        if (activeProgress) {
            clearInterval(interval);

            const { titles } = planLoaderData[activeBar];

            const { time } = titles[step];

            interval = setInterval(() => {
                if (percents[activeBar] === MAX_PERCENTS) {

                    if (activeBar === planLoaderData.length - 1) {
                        clearInterval(interval);

                        return;
                    }

                    setActiveBar(activeBar + 1);

                    return;
                }

                percents[activeBar]++;

                if (percents[activeBar] === titles[step].range[1] && [2,3].includes(titles.length)) {
                    setStep((step + 1) % titles.length);
                }

                setPercents([...percents]);

                if (percents[activeBar] === planLoaderData[activeBar].popUpTime) {
                    toggleActiveProgress(false);
                }

                if (percents[planLoaderData.length - 1] === MAX_PERCENTS) {
                    pageConfiguration.handleOnPageComplete();
                }

            }, time / (titles[step].range[1] - titles[step].range[0]));

        } else {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [activeBar, activeProgress, percents]);

    const {
        bodyColor,
        bodyColorCheckIconPathFill,
        activityColor,
        activityColorCheckIconPathFill,
        nutritionColor,
        nutritionColorCheckIconPathFill,
    } = LOADER_COLORS;

    return (
        <div className={pageWrapper}>
            <div className={pageContent}>
                <img src={logo} alt='Able Men Logo' />

                <h1 className={title}>{t('plan-loader-title')}</h1>

                <div className={loaderWrapper}>
                    <SwitchingTitle
                        titles={planLoaderData[activeBar].titles
                            .map(title => ({
                                ...title,
                                value: t(title.value)
                            }))
                        }
                        percents={percents[activeBar]}
                    />
                    <LinearProgressWithTitleAndLabel
                        percents={percents[0]}
                        title={t('plan-loader-thin-loading-bar-with-title-and-checkbox-body-parameters-title')}
                        color={bodyColor}
                        labelBg={bodyColorCheckIconPathFill}
                    />
                    <LinearProgressWithTitleAndLabel
                        percents={percents[1]}
                        title={t('plan-loader-thin-loading-bar-with-title-and-checkbox-activity-level-title')}
                        color={activityColor}
                        labelBg={activityColorCheckIconPathFill}
                    />
                    <LinearProgressWithTitleAndLabel
                        percents={percents[2]}
                        title={t('plan-loader-thin-loading-bar-with-title-and-checkbox-nutrition-title')}
                        color={nutritionColor}
                        labelBg={nutritionColorCheckIconPathFill}
                    />
                </div>
            </div>

            {!activeProgress && planLoaderData[activeBar].popUpData && (
                <ModalWindow modalWindowFormClassName={modalWindow} hasCloseIcon={false}>
                    <ModalContent
                        onChosen={() => toggleActiveProgress(true)}
                        data={planLoaderData[activeBar].popUpData as PopupData}
                    />
                </ModalWindow>
            )}

            <ReviewsIOWidget store={ABLE_STORE} logoStyle={LOGO_STYLE.inverted} />
        </div>
    );
};
